<template>
  <div>
    <!-- Header with Company Name -->
    <h3 class="title">{{ company }}</h3>

    <v-container fluid class="newsContainer">
      <!-- First Row: Company Summary and Key Metrics -->
      <v-row class="no-gutters">
        <!-- Company Summary -->
        <v-col cols="12" sm="6">
          <v-card class="summary-card">
            <div class="card-content">
              <div v-if="loading" class="loading-container">
                <v-progress-circular indeterminate color="#004D40"></v-progress-circular>
              </div>
              <div v-else-if="error" class="error-message">
                Error: {{ error.message }}
              </div>
              <template v-else>
                <div v-if="truncatedSummary !== companyData.longBusinessSummary">
                  <p class="summary-text">
                    {{ truncatedSummary }}
                    <a href="#" @click.prevent="openModal" class="read-more">Read more</a>
                  </p>
                </div>
                <div v-else>
                  <p class="summary-text">{{ companyData.longBusinessSummary }}</p>
                </div>
                <table class="info-table">
                  <tr>
                    <td class="info-label">Country:</td>
                    <td>{{ companyData.country }}</td>
                  </tr>
                  <tr>
                    <td class="info-label">Currency:</td>
                    <td>{{ companyData.currency }}</td>
                  </tr>
                  <tr>
                    <td class="info-label">Sector:</td>
                    <td>{{ companyData.sector }}</td>
                  </tr>
                  <tr>
                    <td class="info-label">Industry:</td>
                    <td>{{ companyData.industry }}</td>
                  </tr>
                </table>
                <v-dialog v-model="showModal" max-width="1400px" class="company-summary-dialog">
                  <v-card class="company-summary-card">
                    <v-card-title class="company-summary-title">
                      <v-icon class="company-icon">mdi-domain</v-icon>
                      Company Summary
                    </v-card-title>
                    <v-card-text class="company-summary-text">
                      {{ companyData.longBusinessSummary }}
                    </v-card-text>
                    <v-card-actions class="company-summary-actions">
                      <v-spacer></v-spacer>
                      <v-btn class="enhanced-btn" @click="closeModal">
                        <v-icon>mdi-close</v-icon>
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>


              </template>
            </div>
          </v-card>
        </v-col>

        <!-- Key Metrics -->
        <v-col cols="12" sm="3">
          <v-card-title class="card-title">
            Key metrics
    </v-card-title>
        <v-card class="key-metrics-card">

          <v-card-text class="key-metrics-content">
            <div v-if="loading" class="key-metrics-loading text-center">
              <v-progress-circular indeterminate color="#004D40"></v-progress-circular>
            </div>
            <div v-else-if="errorMessage" class="key-metrics-error">
              {{ errorMessage }}
            </div>
            <div v-else>
              <ul class="key-metrics-list">
                <li><strong>Audit Risk:</strong> {{ keyData.auditRisk }}</li>
                <li><strong>Average Volume:</strong> {{ keyData.averageVolume }}</li>
                <li><strong>Average Volume (10 days):</strong> {{ keyData.averageVolume10days }}</li>
                <li><strong>Board Risk:</strong> {{ keyData.boardRisk }}</li>
                <li><strong>Book Value:</strong> {{ keyData.bookValue }}</li>
                <li><strong>Compensation Risk:</strong> {{ keyData.compensationRisk }}</li>
                <li><strong>Earnings Growth:</strong> {{ keyData.earningsGrowth }}</li>
                <li><strong>Full-Time Employees:</strong> {{ keyData.fullTimeEmployees }}</li>
                <li v-if="keyData.irWebsite">
                  <a :href="keyData.irWebsite" target="_blank" class="key-metrics-link">Investor Relations Website</a>
                </li>
              </ul>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      </v-row>

      <!-- Second Row: Insider Trade, Warning Signs, Financial Data -->
      <v-row class="no-gutters">
        <v-col cols="12" sm="4">
        <row>
        <!-- Insider Trade -->
  <v-card class="insider-trade-card">
    <v-card-title class="card-title">
      Insider Trade (Last 12 months)
    </v-card-title>
    <v-card-text>
      <div class="consensus-graph-container">
        <div class="consensus-bar-container">
          <div class="consensus-bar-highlight consensus-green" :style="{ width: greenBarWidth + '%' }"></div>
          <div class="consensus-bar-highlight consensus-red" :style="{ width: redBarWidth + '%' }"></div>
        </div>
        <div class="consensus-labels">
          <div class="consensus-label" v-for="(label, index) in consensusLabels" :key="index">{{ label }}</div>
        </div>
      </div>
    </v-card-text>
  </v-card>

        </row>
        <row>
        <!-- Warning Signs -->
        <div>
    <!-- Warning Signs Card -->
        <v-card class="warning-signs-card">
          <div class="warning-block GoodSign" @click="openDialog('goodSigns')">
            <h3>Good Signs ({{ warningSigns.good[0] }})</h3>
          </div>
          <div class="warning-block MediumSign" @click="openDialog('mediumWarning')">
            <h3>Medium Warning ({{ warningSigns.medium[0] }})</h3>
          </div>
          <div class="warning-block SevereSign" @click="openDialog('warning')">
            <h3>Severe Warning ({{ warningSigns.severe[0] }})</h3>
          </div>
        </v-card>

        <!-- Dialog -->
        <v-dialog v-model="dialog" max-width="600px" class="enhanced-signs-dialog">
  <v-card class="enhanced-card">
    <!-- Conditional Titles with Icons -->
    <v-card-title class="enhanced-dialog-title">
      <v-icon v-if="categoryToUpdate === 'goodSigns'" color="#48ab95" class="mr-2 large-icon">mdi-thumb-up</v-icon>
      <v-icon v-else-if="categoryToUpdate === 'mediumWarning'" color="#fcad5d" class="mr-2 large-icon">mdi-alert</v-icon>
      <v-icon v-else-if="categoryToUpdate === 'warning'" color="#F08080" class="mr-2 large-icon">mdi-alert-circle</v-icon>
      <span>
        {{ categoryToUpdate === 'goodSigns' ? 'Good Signs' : 
            categoryToUpdate === 'mediumWarning' ? 'Medium Warning' : 
            'Severe Warning' }}
      </span>
    </v-card-title>

    <v-card-text>
      <ul class="enhanced-signs-list">
        <!-- List with custom icons and colors -->
        <li v-for="(sign, index) in getSignsList" :key="index" class="sign-item">
          <v-icon left small v-if="categoryToUpdate === 'goodSigns'" class="good-sign-icon">mdi-check-circle</v-icon>
          <v-icon left small v-else-if="categoryToUpdate === 'mediumWarning'" class="warning-sign-icon">mdi-alert</v-icon>
          <v-icon left small v-else-if="categoryToUpdate === 'warning'" class="severe-sign-icon">mdi-alert-circle</v-icon>
          {{ sign }}
        </li>
      </ul>
    </v-card-text>

    <v-card-actions class="enhanced-dialog-actions">
      <v-spacer></v-spacer>
      <v-btn class="enhanced-btn" @click="dialog = false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


      </div>
        </row>
        </v-col>
        <!-- Financial Data -->
        <v-col cols="12" sm="5">
          <v-card class="financial-data-card">
            <div class="financial-data-container">
              <div v-if="financeLoading" class="loading-container">
                <v-progress-circular indeterminate color="#004D40"></v-progress-circular>
              </div>
              <div v-else-if="financeError" class="error-message">
                {{ financeError }}
              </div>
              <canvas ref="financialChart"></canvas>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- Third Row: Charts and News Card -->
      <v-row class="no-gutters">
        <!-- Geographic Chart -->
        <v-col cols="12" sm="3">
          <v-card class="chart-card">
            <div v-if="geoLoading" class="loading-container">
              <v-progress-circular indeterminate color="#004D40"></v-progress-circular>
            </div>
            <div v-else-if="geoError" class="error-message">
              {{ geoError }}
            </div>
            <canvas ref="geoChart" width="200" height="200"></canvas>
          </v-card>
        </v-col>

        <!-- Pie Charts -->
        <v-col cols="12" sm="3">
          <v-card class="chart-card">
            <div v-if="sectorLoading" class="loading-container">
              <v-progress-circular indeterminate color="#004D40"></v-progress-circular>
            </div>
            <div v-else-if="sectorError" class="error-message">
              {{ sectorError }}
            </div>
            <canvas ref="sectorChart" width="200" height="200"></canvas>
          </v-card>
        </v-col>

        <v-col cols="12" sm="3">
          <v-card class="chart-card">
            <div v-if="investorLoading" class="loading-container">
              <v-progress-circular indeterminate color="#004D40"></v-progress-circular>
            </div>
            <div v-else-if="investorError" class="error-message">
              {{ investorError }}
            </div>
            <canvas ref="investorsChart" width="200" height="200"></canvas>
          </v-card>
        </v-col>

        <!-- News Card -->
        <v-col cols="12" sm="9">
          <v-card class="news-table-card">
            <table border="1" class="styled-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Synthetical News</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="newsData.length === 0">
                  <td colspan="2">Unfortunately, there are no news available at this time.</td>
                </tr>
                <tr v-else v-for="(newsItem, index) in newsData" :key="index" @click="showNewsContent(newsItem)">
                  <td>{{ newsItem.date }}</td>
                  <td>{{ newsItem.news }}</td>
                </tr>
              </tbody>
            </table>
            <v-dialog v-model="showDialog" max-width="1400px" class="company-summary-dialog">
  <v-card class="company-summary-card">
    <v-card-title class="company-summary-title">
      <v-icon class="company-icon">mdi-newspaper</v-icon>
      {{ selectedNewsItem.news }}
    </v-card-title>
    <v-card-text class="company-summary-text" v-html="selectedNewsItem.content"></v-card-text>
    <v-card-actions class="company-summary-actions">
      <v-spacer></v-spacer>
      <v-btn class="enhanced-btn" @click="showDialog = false">
        <v-icon>mdi-close</v-icon>
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>




          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.title {
  font-family: 'Poppins', sans-serif;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #004d40;
  margin: 20px 0;
}

.newsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  padding: 0 10px; /* Optional: add padding to avoid content touching edges */
  min-height: 100vh;
}

/* Flex layout adjustments */
.newsContainer .v-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center columns horizontally */
  width: 100%;
}

.newsContainer .v-col {
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: flex-start; /* Align items to the top */
  margin-bottom: 20px; /* Space between rows */
}


.summary-card,
.key-metrics-card,
.warning-signs-card,
.financial-data-card,
.insider-trade-card,
.chart-card,
.news-table-card {
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #ffffff;
}

.summary-card:hover,
.key-metrics-card:hover,
.warning-signs-card:hover,
.financial-data-card:hover,
.insider-trade-card:hover,
.chart-card:hover,
.news-table-card:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.key-metrics-card {
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 5px;
  max-height: 250px; 
  overflow: auto; 
}

.key-metrics-content {
  padding: 5px 0;
}

.key-metrics-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.key-metrics-list li {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.key-metrics-list li:last-child {
  border-bottom: none;
}

.key-metrics-list strong {
  color: #004D40;
}

.key-metrics-error {
  color: red;
  text-align: center;
  font-weight: bold;
}

.key-metrics-link {
  color: #004D40;
  text-decoration: underline;
}

.key-metrics-link:hover {
  color: #002F2F;
}

.key-metrics-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}


.card-content {
  padding: 16px;
}

.summary-text {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  margin-bottom: 20px;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
}

.info-table td {
  padding: 8px;
  border: 1px solid #ddd;
}

.info-label {
  font-weight: bold;
}

.warning-block {
  padding: 4px;
  cursor: pointer;
  margin: 5px;
  border-radius: 4px;
  text-align: center;
}
.GoodSign {
  background-color: #48ab95; /* Green */
  border: 2px solid #388e3c; /* Darker green */
  color: #f8f9fa;
}

.MediumSign {
  background-color: #fcad5d; /* Yellow */
  border: 2px solid #fc9a5d; /* Darker yellow */
  color: #f8f9fa;
}

.SevereSign {
  background-color: #F08080; /* Red */
  border: 2px solid #c62828; /* Darker red */
  color: #f8f9fa;
}

.warning-block:hover {
  background-color: #BBDEFB;

}

.insider-trade-card {
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  margin: 20px;
  height: 33%; 
}


.card-title {
  background: #BBDEFB;
  color: #004d40;
  padding: 4px;
  text-align: center;
  font-size: 0.85rem;
  font-weight: bold;
  border-radius: 12px 12px 0 0;
}

.card-title-text {
 color: #004d40;
}
.consensus-graph-container {
  padding: 16px;
}

.consensus-bar-container {
  width: 80%;
  margin: 0 auto; /* Center horizontally */
  display: flex;
  align-items: center;
  height: 20px;
  background: #f0f0f0;
  border-radius: 10px;
  position: relative;
  overflow: hidden; /* Ensure bars don't overflow */
}

.consensus-bar-highlight {
  height: 100%;
  position: absolute;
  top: 0;
  transition: width 0.3s ease;
}

.consensus-green {
  background-color: #48ab95; /* Green for positive values */
}

.consensus-red {
  background-color: #F08080; /* Darker red for negative values */
}

.consensus-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.consensus-label {
  font-size: 12px;
  color: #004d40;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
}

.styled-table thead tr {
  background-color: #48ab95;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

.error-message {
  color: red;
  text-align: center;
}

.read-more {
  color: #48ab95;
  text-decoration: underline;
}

.news-table-card {
  margin-top: 20px;
}

.chart-card canvas {
  width: 100% !important;
  height: auto !important;
}
/* Enhanced Dialog Container */
.enhanced-signs-dialog .v-card {
  border-radius: 12px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1); /* Soft shadow */
  background: linear-gradient(135deg, #e8f5e9, #fafafa); /* Subtle gradient */
  padding: 20px;
}

/* Title Styling */
.enhanced-dialog-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #004d40; /* Dark teal */
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.large-icon {
  font-size: 2rem; /* Large icon for emphasis */
}

/* Signs List */
.enhanced-signs-list {
  padding-left: 0;
  list-style-type: none;
  font-size: 1.3rem;
  line-height: 1.8;
  margin: 0;
}

.sign-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px; /* Space between list items */
  color: #004d40; /* Main text color */
}

.good-sign-icon {
  color: #48ab95; /* Green for good signs */
  margin-right: 10px;
}

.warning-sign-icon {
  color: #fcad5d; /* Orange for medium warning */
  margin-right: 10px;
}

.severe-sign-icon {
  color: #F08080; /* Red for severe warning */
  margin-right: 10px;
}

/* Dialog Actions (Button Area) */
.enhanced-dialog-actions {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.enhanced-btn {
  color: white;
  background-color: #48ab95;
  transition: background-color 0.3s ease;
}

.enhanced-btn:hover {
  background-color: #004d40; /* Darker green on hover */
}
/* Enhanced Dialog Container */
.company-summary-dialog .v-card {
  border-radius: 12px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1); /* Soft shadow */
  background: #ffffff; /* Default white background */
  padding: 20px;
}

/* Card Background Color */
.company-summary-card {
  background-color: #f9f9f9; /* Light grey background for a soft look */
}

/* Title Styling */
.company-summary-title {
  font-size: 2rem; /* Larger title font size */
  font-weight: 700;
  color: #004d40; /* Dark teal */
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #48ab95; /* Bottom border for emphasis */
  padding-bottom: 10px;
}

.company-icon {
  font-size: 2.5rem; /* Larger icon for emphasis */
  margin-right: 15px;
}

/* Summary Text Styling */
.company-summary-text {
  font-size: 1.25rem; /* Slightly larger font size */
  line-height: 1.8; /* Increased line height for better readability */
  color: #333; /* Dark grey text for readability */
  margin-bottom: 20px;
  padding: 10px; /* Padding around text for a better layout */
  border-left: 4px solid #48ab95; /* Left border to highlight text area */
  background-color: #fcfcfc; /* Slightly off-white background for text area */
}

/* Actions (Button Area) */
.company-summary-actions {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

</style>




<script>
import Chart from 'chart.js/auto';
import axios from 'axios'
export default {
  name: 'CompagnySummary',
  data() {
    return {
      companyData: {},
      loading: true,
      geoLoading: false,
      sectorLoading: false,
      investorLoading: false,
      financeLoading: false,
      geoError: "",
      sectorError: "",
      investorError: "",
      financeError: "",
      error: null,
      showModal: false,

      dialog: false,
      categoryToUpdate: '',
      warningSigns: {
        good: [0, []],
        medium: [0, []],
        severe: [0, []],
      },
      keyData: {
        auditRisk: 0,
        averageVolume: 0,
        averageVolume10days: 0,
        boardRisk: 0,
        bookValue: 0,
        compensationRisk: 0,
        earningsGrowth: 0,
        fullTimeEmployees: 0,
        irWebsite: ''
      },
      consensusLabels: [],
      insiderTrade: 0.5,
      newsData: [],
      selectedNewsItem: null,
      showDialog: false,
    };
  },
  props: {
    company: String
  },
  computed: {
    truncatedSummary() {
      if (this.companyData.longBusinessSummary.length > 200) {
        return this.companyData.longBusinessSummary.substring(0, 200) + '...';
      }
      return this.companyData.longBusinessSummary;
    },
    greenBarWidth() {
      return 100;
    },
    redBarWidth() {
      return ((1 - this.insiderTrade) * 100);
    },
    getSignsList() {
    if (this.categoryToUpdate === 'goodSigns') {
      return this.goodSignsTextsList;
    } else if (this.categoryToUpdate === 'mediumWarning') {
      return this.mediumWarningTextsList;
    } else if (this.categoryToUpdate === 'warning') {
      return this.warningTextsList;
    }
    return [];
  },
},

mounted() {
  this.$nextTick(() => {
    this.initGeoChart();
    this.initSectorChart();
    this.initInvestorsChart();
    this.createFinancialChart();
    this.fetchWarningSigns();
  });
},
  methods: {
    showNewsContent(newsItem) {
    this.selectedNewsItem = newsItem;
    this.showDialog = true;
  },
    openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        openDialog(category) {
  this.dialog = true;
  this.categoryToUpdate = category;

  if (this.warningSigns) {
    if (category === 'goodSigns' && this.warningSigns.good && Array.isArray(this.warningSigns.good) && this.warningSigns.good.length > 1) {
      this.goodSignsTextsList = this.warningSigns.good[1] || [];
    } else if (category === 'mediumWarning' && this.warningSigns.medium && Array.isArray(this.warningSigns.medium) && this.warningSigns.medium.length > 1) {
      this.mediumWarningTextsList = this.warningSigns.medium[1] || [];
    } else if (category === 'warning' && this.warningSigns.severe && Array.isArray(this.warningSigns.severe) && this.warningSigns.severe.length > 1) {
      this.warningTextsList = this.warningSigns.severe[1] || [];
    }
  }
},



    closeDialog() {
      this.dialog = false;
      this.categoryToUpdate = "";
      this.goodSignsTextsList = [];
      this.mediumWarningTextsList = [];
      this.warningTextsList = [];
    },

async initGeoChart() {
  this.geoLoading = true;
  try {
    await new Promise(resolve => setTimeout(resolve, 2000));
    const response = await fetch('https://api.foliosentry.com/api/sector?company=' + this.company);
    const data = await response.json();

    const worldSegmentation = data.world_segmentation;
    const geoLabels = [];
    const geoData = [];

    for (const [key, value] of Object.entries(worldSegmentation)) {
      geoLabels.push(key);
      geoData.push(parseFloat(value)); // Convert string percentage to float
    }
    if (geoLabels.length == 0 || geoData.length == 0){
      this.geoError = "None"
    }
    const geoChartCtx = this.$refs.geoChart ? this.$refs.geoChart.getContext('2d') : null;

    if (geoChartCtx) {
      new Chart(geoChartCtx, {
        type: 'pie',
        data: {
          labels: geoLabels,
          datasets: [{
            label: 'Geographical Revenue Distribution',
            data: geoData,
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'], // You can customize colors as per your preference
          }]
        },
        options: {
          // Customize options as needed
        }
      });
    }
    this.geoLoading = false;
  } catch (error) {
    this.geoLoading = false;
    this.geoError = "N/A"
    console.error('Error fetching data from API initGeoChart:', error);
  }
},

async initSectorChart() {
    this.sectorLoading = true;
    try {
        await new Promise(resolve => setTimeout(resolve, 2000));

        const response = await fetch('https://api.foliosentry.com/api/sector?company=' + this.company);
        const data = await response.json();

        // Extract production segmentation data
        const productionSegmentation = data.production_segmentation;
        const sectorLabels = [];
        const sectorData = [];
        
        for (const [key, value] of Object.entries(productionSegmentation)) {
            sectorLabels.push(key);
            sectorData.push(parseFloat(value)); // Convert string percentage to float
        }
        if (sectorLabels.length == 0 || sectorData. length == 0){
            this.sectorError = "None"
          }
        // Get the canvas context
        const sectorChartCtx = this.$refs.sectorChart.getContext('2d');
        
        // Create a pie chart with the extracted data
        new Chart(sectorChartCtx, {
            type: 'pie',
            data: {
                labels: sectorLabels,
                datasets: [{
                    label: 'Sectorial Revenue Distribution',
                    data: sectorData,
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'], // Customize colors as needed
                }]
            },
            options: {
                // Customize options as needed
            }
        });
        this.sectorLoading = false;
    } catch (error) {
        this.sectorLoading = false;
        this.sectorError = "N/A"
        console.error('Error fetching data from API initSectorChart:', error);
    }
},
async initInvestorsChart() {
  this.investorLoading = true;
  try {
  await new Promise(resolve => setTimeout(resolve, 2000));

  // Fetch data from the API endpoint
  const response = await fetch('https://api.foliosentry.com/api/holder?company=' + this.company);
  const data = await response.json();

  // Parse the data received from the API
  const heldByAllInsider = parseFloat(data["Insiders"]);
  const heldByInstitutions = parseFloat(data["Institutions"]);
  const heldByRetailInvestors = parseFloat(data["Retail Investors"]);
  if (heldByAllInsider.length == 0 && heldByInstitutions.length == 0 && heldByRetailInvestors.length == 0) {
    this.investorError = "None"
  }
  // Ensure that the investorsChart ref is not null before trying to access it
  const investorsChartRef = this.$refs.investorsChart;
  if (investorsChartRef) {
    // Get the chart context
    const investorsChartCtx = investorsChartRef.getContext('2d');

    // Create a new pie chart using the data from the API
    new Chart(investorsChartCtx, {
      type: 'pie',
      data: {
        labels: ['All Insider', 'Institutions', 'Retail Investors'],
        datasets: [{
          label: 'Top Investors',
          data: [heldByAllInsider, heldByInstitutions, heldByRetailInvestors], // Use data from API
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        }]
      },
      options: {
        // Customize options as needed
      }

        });
        this.investorLoading = false; 
      }
    }
    catch (error) {
        this.investorLoading = false;
        this.investorError = "N/A"
        console.error('Error fetching data from API initSectorChart:', error);
    }
    },
    async createFinancialChart() {
      this.financeLoading = true;
    const ctx = this.$refs.financialChart.getContext('2d');

    try {
      await new Promise(resolve => setTimeout(resolve, 2000));
        // Fetch data from the API
        const response = await fetch('https://api.foliosentry.com/api/income_statement?company=' + this.company);
        
        // Parse the response as JSON
        const data = await response.json();

        // Initialize arrays for storing BNA, Dividend, and Ratio data
        const BNAData = [];
        const dividendData = [];
        const ratioData = [];
        const labels = [];

        // Convert the object data to an array of objects
        const financialData = Object.entries(data).map(([date, info]) => ({
            year: date.split("-")[0],
            BNA: info.BNA,
            dividende_par_action: info["Dividende par action"]
        }));

        // Parse the financial data and extract relevant data
        const filteredFinancialData = financialData.filter(item => {
            return typeof item.BNA === 'number' && !isNaN(item.BNA) && item.BNA !== null && item.BNA !== undefined;
        });

        filteredFinancialData.forEach((item) => {
            labels.push(item.year);
            BNAData.push(item.BNA);
            dividendData.push(item.dividende_par_action);
            ratioData.push(item.dividende_par_action / item.BNA);
        });

        // Create the chart
        this.financialChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels, // Use years from the converted data
                datasets: [
                    {
                        label: 'BNA',
                        data: BNAData,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)', // Blue color for BNA bars
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1
                    },
                    {
                        label: 'Dividend',
                        data: dividendData,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)', // Red color for dividend bars
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1
                    },
                    {
                        label: 'BNA/Dividend Ratio',
                        type: 'line',
                        data: ratioData,
                        borderColor: 'rgba(75, 192, 192, 1)', // Turquoise color for the line graph
                        borderWidth: 1,
                        fill: false,
                        yAxisID: 'percentage-axis'
                    }
                ]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'USD'
                        },
                        position: 'left',
                        id: 'USD-axis'
                    },
                    'percentage-axis': {
                        display: true,
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Percentage'
                        },
                        position: 'right',
                        grid: {
                            drawOnChartArea: false // Do not draw grid for percentage axis
                        }
                    }
                }
            }
        });
        this.financeLoading = false;
    } catch (error) {
      this.financeLoading = false;
      this.financeError = "N/A"
        console.error('Error fetching data from API createFinancialChart:', error);
    }
},
calculateRatio(data) {
  const total = parseInt(data.sell_value.replace(/,|\s/g, '')) + parseInt(data.purchase_value.replace(/,|\s/g, ''));
  if (total === 0) {
    return 0.5;
  }
  if (data.sell_value === '0 Shares') {
    return 1;
  }
  return parseInt(data.purchase_value.replace(/,|\s/g, '')) / total;
},
updateDisplay(data) {
  // Extract the number of shares sold and purchased from the API response
  const sellShares = data.sell_value.split(' ')[0];
  const buyShares = data.purchase_value.split(' ')[0];

  // Create the consensus labels array with the number of shares sold and purchased,
  // and the separator (' | ') in between
  this.consensusLabels = [
    `There are ${buyShares} stocks bought.`, // number of shares purchased
    `There are ${sellShares} stocks sold.`, // number of shares sold
  ];
},
async fetchInsiderData() {
  try {
    const response = await fetch('https://api.foliosentry.com/api/insiderTransaction?company=' + this.company);
    if (!response.ok) {
      throw new Error(`https error! status: ${response.status}`);
    }
    const data = await response.json();
    if (!data || typeof data !== 'object' || Array.isArray(data)) {
      console.error('Invalid data format received from the API');
      return;
    }
    this.insiderTrade = this.calculateRatio(data);
    
    this.updateDisplay(data);
  } catch (error) {
    console.error('Error fetching insider data:', error);
  }
},
fetchNewsData() {
      axios.get('https://api.foliosentry.com/api/news?company=' + this.company)
        .then(response => {
          this.newsData = response.data.map(item => ({
            date: item.date,
            news: item.title,
            content: item.content
          }))
        })
        .catch(error => {
          console.error(error)
        })
    },
    fetchCompanyData() {
    this.loading = true;
    this.error = null;
    axios
      .get(`https://api.foliosentry.com/api/companyPresentation?company=${this.company}`)
      .then((response) => {
        this.companyData = response.data;
        this.loading = false;
      })
      .catch((error) => {
        this.error = error;
        this.loading = false;
      });
  },
  
  async fetchKeyData() {
      try {
        const response = await fetch('https://api.foliosentry.com/api/keyMetrics?company=' + this.company);
        const data = await response.json();

        // Update keyData with the API response
        this.keyData = data;
      } catch (error) {
        console.error('Error fetching data from API fetchKeyData: ', error);
      }
  },
  async fetchWarningSigns() {
    try {
    const response = await axios.get('https://api.foliosentry.com/api/warning_signs?company=' + this.company);
    this.warningSigns = response.data;
  } catch (error) {
    console.error(error);
  }
  },
},
  created() {
    this.fetchNewsData()
    this.fetchCompanyData();
    this.fetchKeyData()
    this.fetchInsiderData();
  },
};
</script>