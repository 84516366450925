<template>
    <div>
        <MyNavPanel :selectedCompagny="companyList" @selected-company="currentCompagny"/>
    </div>
    <div v-for="(item, index) in this.companyList" :key="index" >
        <Invest :company="item"/>  
    </div>
</template>

<script>
import MyNavPanel from '../components/Navpanel.vue'
import Invest from '../components/InvestPromo.vue'

export default {
name: "StartInvest",
data() {
    return{
      companyList: [],
    }
  },
  methods: {
    currentCompagny (value) {
      this.companyList = value
    }
  },
components: {
    MyNavPanel,
    Invest
},
};

</script>