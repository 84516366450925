import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { createRouter, createWebHashHistory } from 'vue-router';
import { createMetaManager } from 'vue-meta';

import HomePage from './views/HomePage.vue';
import MyDashboard from './views/Home.vue';
import FinancialData from './views/FinancialData.vue';
import InvestorSummary from './views/InvestorSummary.vue';
import Performance from './views/Performance.vue';
import StartInvest from './views/StartInvest.vue';

// Define routes with meta information
const routes = [
  {
    name: 'Home',
    path: '/',
    component: HomePage,
    meta: {
      title: 'Home - FolioSentry',
      description: 'Welcome to Folio Sentry. Simplify your investment decisions.'
    }
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: MyDashboard,
    meta: {
      title: 'Dashboard - FolioSentry',
      description: 'View your dashboard with key financial metrics and insights.'
    }
  },
  {
    name: 'FinancialData',
    path: '/financial-data',
    component: FinancialData,
    meta: {
      title: 'Financial Analysis - FolioSentry',
      description: 'Analyze financial data and trends of your portfolio.'
    }
  },
  {
    name: 'InvestorSummary',
    path: '/investor-summary',
    component: InvestorSummary,
    meta: {
      title: 'Summary & News - FolioSentry',
      description: 'Read summaries and latest news related to your investments.'
    }
  },
  {
    name: 'Performance',
    path: '/performance',
    component: Performance,
    meta: {
      title: 'Performance Metrics - FolioSentry',
      description: 'Evaluate the performance of your portfolio with detailed metrics.'
    }
  },
  {
    name: 'StartInvest',
    path: '/start',
    component: StartInvest,
    meta: {
      title: 'Start Investing - FolioSentry',
      description: 'Begin managing your investments and portfolio effectively.'
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

const app = createApp(App);

// Initialize vue-meta
const metaManager = createMetaManager();

app.use(router);
app.use(vuetify);
app.use(metaManager);

app.mixin({
  metaInfo() {
    const meta = this.$route.meta || {};
    return {
      title: meta.title || 'FolioSentry',
      meta: [
        { name: 'description', content: meta.description || '' },
        { property: 'og:title', content: meta.title || '' },
        { property: 'og:description', content: meta.description || '' }
      ]
    };
  }
});

app.mount('#app');
