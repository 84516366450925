<template>
    <!-- Header App Bar -->
    <v-app-bar app color="#2196F3" dark elevate-on-scroll>
      <v-toolbar-title>
        <router-link to="/" class="header-logo">Folio Sentry</router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="tabs-container d-none d-md-flex">
        <v-btn
          v-for="(tab, index) in tabsList"
          :key="index"
          text
          class="headerButton"
          :class="{ activeTab: tabs === index }"
          @click="handleTabClick(index)"
        >
          {{ tab.label }}
        </v-btn>
      </div>
      <v-btn icon @click="toggleMenu" class="d-md-none">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Navigation Drawer for Mobile -->
    <v-navigation-drawer
      v-model="menu"
      app
      temporary
      right
      class="menu-drawer"
      :style="{ top: '64px', zIndex: 2000 }"
    >
      <v-list>
        <v-list-item
          v-for="(tab, index) in tabsList"
          :key="index"
          @click="handleMenuItemClick(index)"
        >
          <v-list-item-title>{{ tab.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'MyHeader',

  setup() {
    const tabs = ref(null);
    const route = useRoute();
    const router = useRouter();
    const menu = ref(false);
    const companyList = ref([]); // Initialize companyList or fetch from an API

    const tabsList = [
      { label: 'Dashboard', route: '/dashboard' },
      { label: 'Financial Analysis', route: '/financial-data' },
      { label: 'Summary & News', route: '/investor-summary' },
      { label: 'Performance', route: '/performance' },
    ];

    const updateTabs = () => {
      const routeIndex = tabsList.findIndex(tab => tab.route === route.path);
      tabs.value = routeIndex !== -1 ? routeIndex : null;
    };

    watch(route, updateTabs);
    onMounted(updateTabs);

    const handleTabClick = (index) => {
      tabs.value = index;
      router.push(tabsList[index].route);
    };

    const handleMenuItemClick = (index) => {
      tabs.value = index;
      router.push(tabsList[index].route);
      menu.value = false; // Close the menu after selection
      toggleBodyScroll(false); // Allow body scroll
    };

    const toggleMenu = () => {
      menu.value = !menu.value;
      toggleBodyScroll(menu.value); // Prevent body scroll when menu is open
    };

    const toggleBodyScroll = (disable) => {
      document.body.style.overflow = disable ? 'hidden' : '';
    };

    const handleSelectedCompany = (company) => {
      // Handle selected company here
      console.log('Selected company:', company);
    };

    return { tabs, tabsList, handleTabClick, handleMenuItemClick, menu, toggleMenu, companyList, handleSelectedCompany };
  },
};
</script>

<style scoped>
.headerButton {
  color: #ffffff !important;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0 15px;
  display: inline-flex;
  align-items: center;
}

.headerButton:hover {
  color: #b2dfdb !important;
}

.activeTab {
  border-bottom: 2px solid #b2dfdb !important;
}

.header-logo {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
}

.v-app-bar {
  background: linear-gradient(90deg, #004d40, #00695c);
}

.tabs-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0 10px;
}

.tabs-container::-webkit-scrollbar {
  height: 6px;
}

.tabs-container::-webkit-scrollbar-thumb {
  background: #b2dfdb;
  border-radius: 10px;
}

.menu-drawer {
  width: 250px;
  height: calc(100vh - 64px);
  position: fixed;
  top: 0;
  right: 0;
  background: white;
}

.nav-panel-container {
  padding: 16px; /* Add padding or margin if needed */
}
</style>
