<template>
  <v-container>
    <div v-if="error">
      <v-card class="errorCard" elevation="3">
        <div class="text-center">
          <p class="large-text">{{ error }}</p>
        </div>
      </v-card>
    </div>
    <div v-else>
      <!-- Main Content Row -->
      <v-row justify="center" class="mainRow">
        <v-col cols="12" md="10">
          <v-card class="mainCard" elevation="4" outlined>
            <v-row align="center" justify="space-between">
              <!-- Rating Score on the left -->
              <v-col cols="auto" class="text-left">
                <v-btn icon class="discreet-btn" @click="fetchData(selectedTimeFrame)">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </v-col>

              <!-- Rating Score on the right -->
              <v-col cols="auto" class="text-right">
                <div class="rating-container">
                  <span v-for="index in 5" :key="index" class="star" :class="{ 'selected': index <= ratingScore }">&#9733;</span>
                </div>
                <div class="text-center">{{ ratingInfo.rating_score }}</div>
              </v-col>
            </v-row>

            <v-card-title class="text-center">
              <h3 class="Title">{{ company }}</h3>
            </v-card-title>

            <v-card-text>
              <v-divider></v-divider>

              <v-row class="my-3">
                <v-col cols="12" md="8">
                  <v-sheet class="px-3 py-2 radioButton">
                    <strong>Daily price:</strong> {{ lastValue }}<br>
                    <strong>Period growth:</strong>
                    <span :class="{'positive': roundedPercentage > 0, 'negative': roundedPercentage < 0}">
                      {{ formatPercentage(roundedPercentage) }}
                    </span>
                    <v-row justify="center">
                    <v-col cols="auto">
                      <v-radio label="5D" value="5d" v-model="selectedTimeFrame" @click="selectedTimeFrame = '5d'; fetchData('5d')"></v-radio>
                    </v-col>
                    <v-col cols="auto">
                      <v-radio label="1M" value="1m" v-model="selectedTimeFrame" @click="selectedTimeFrame = '1m'; fetchData('1m')"></v-radio>
                    </v-col>
                    <v-col cols="auto">
                      <v-radio label="3M" value="3m" v-model="selectedTimeFrame" @click="selectedTimeFrame = '3m'; fetchData('3m')"></v-radio>
                    </v-col>
                    <v-col cols="auto">
                      <v-radio label="YTD" value="YTD" v-model="selectedTimeFrame" @click="selectedTimeFrame = 'YTD'; fetchData('YTD')"></v-radio>
                    </v-col>
                    <v-col cols="auto">
                      <v-radio label="1Y" value="1Y" v-model="selectedTimeFrame" @click="selectedTimeFrame = '1Y'; fetchData('1Y')"></v-radio>
                    </v-col>
                    <v-col cols="auto">
                      <v-radio label="5Y" value="5Y" v-model="selectedTimeFrame" @click="selectedTimeFrame = '5Y'; fetchData('5Y')"></v-radio>
                    </v-col>
                  </v-row>
                  </v-sheet>
                </v-col>

                <v-col cols="12" md="4" class="text-center">
                  <v-btn class="switch-btn" :loading="isLoading2" @click="toggleChart">
                    {{ showChart1 ? 'Linear Chart' : 'Stock Chart' }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <canvas v-if="showChart1 && !isLoading" ref="myChart" class="chart"></canvas>
                  <canvas v-if="!showChart1 && !isLoading" ref="myChart2" class="chart"></canvas>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-row justify="center" class="my-4">
                <v-col v-for="(item, index) in cardItems" :key="index" cols="12" md="4" class="d-flex">
                  <v-card :color="getColor(item.value, item.thresholdRule)" outlined class="flex-grow-1">
                    <v-card-text class="text-center">
                      <p class="qualityValue">{{ item.label }}: {{ item.value }}</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Second Row for Additional Cards -->
      <v-row justify="center" class="secondRow">
  <v-col cols="12" lg="4" class="d-flex flex-column">
    <!-- Consensus Recommendation and Target Price in the Same Column -->
    <v-card class="enhanced-consensus-card flex-grow-1 mb-4" elevation="8">
      <v-card-title class="enhanced-consensus-title">
        <v-icon class="consensus-icon">mdi-chart-bar</v-icon>
        <strong>Consensus Recommendation</strong>
      </v-card-title>
      <v-card-text>
        <div class="enhanced-consensus-graph-container">
          <div class="enhanced-consensus-bar-container">
            <div 
              class="enhanced-consensus-bar-highlight" 
              :style="{ width: barWidth + '%'}" 
              :class="{ 'positiveConsensus': analystConsensus > 2.5, 'negativeConsensus': analystConsensus <= 2.5 }">
            </div>
          </div>
          <div class="enhanced-consensus-labels">
            <div class="enhanced-consensus-label">Strong Sell</div>
            <div class="enhanced-consensus-label">Sell</div>
            <div class="enhanced-consensus-label">Hold</div>
            <div class="enhanced-consensus-label">Buy</div>
            <div class="enhanced-consensus-label">Strong Buy</div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="target-price-card flex-grow-1" elevation="8">
      <v-card-title class="target-price-title">
        <v-icon class="target-price-icon">mdi-currency-usd</v-icon>
        <strong>Target Price</strong>
      </v-card-title>
      <v-card-text class="target-price-text">
        <table class="target-price-table">
          <tbody>
            <tr>
              <td class="table-label">Number of Analysts:</td>
              <td>{{ analystNumber }}</td>
            </tr>
            <tr>
              <td class="table-label">Target Price:</td>
              <td>${{ targetPrice }}</td>
            </tr>
            <tr>
              <td class="table-label">To Target (%):</td>
              <td :class="{ 'positive': growthToTarget >= 0, 'negative': growthToTarget < 0 }">
                {{ growthToTarget.toFixed(2) }}%
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>
  </v-col>

  <v-col cols="12" lg="6">
  <v-card class="radar-card flex-grow-1" elevation="8">
    <v-card-title class="target-price-title">
        <v-icon class="target-price-icon">mdi-chart-line</v-icon>
        <strong>Analyst Opinion</strong>
      </v-card-title>
    <v-card-text class="radar-card-text">
      <canvas ref="radarChart" class="radar-chart"></canvas>
    </v-card-text>
  </v-card>
</v-col>

</v-row>

    </div>
  </v-container>
</template>

<style scoped>
.Title {
  font-size: 24px;
  margin: 16px 0;
  color: #004d40;
}

.radioButton {
  background-color: #f8f9fa;
}

.large-text {
  font-size: 15px;
}

.mainRow {
  margin-bottom: 16px;
}

.mainCard {
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  margin-top: 5%;
}

.consensusCard,
.targetPriceCard,
.radarCard {
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 16px;
}

.targetPriceCard {
  margin-top: 9%;
}

.discreet-btn {
  background: transparent;
  color: #004d40;
  margin-right: 8px;
}

.switch-btn {
  color: white;
  background-color: #48ab95;
  border-radius: 50px;
}

.rating-container {
  display: flex;
  justify-content: center;
  font-size: 30px;
}

.star {
  color: #BBDEFB;
  margin-right: 4px;
}

.star.selected {
  color: #ffd700;
}
/* Enhanced Consensus Card */
.enhanced-consensus-card {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to top right, #ffffff, #f5f5f5);
}

.enhanced-consensus-title {
  background-color: #48ab95;
  color: white;
  padding: 16px;
  border-bottom: 2px solid #004d40;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}

.consensus-icon {
  margin-right: 8px;
  font-size: 1.5rem;
}

.enhanced-consensus-graph-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enhanced-consensus-bar-container {
  width: 100%;
  height: 24px;
  border-radius: 12px;
  background-color: #BBDEFB;
  position: relative;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.enhanced-consensus-bar-highlight {
  height: 100%;
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #48ab95;
  transition: width 0.4s ease-in-out;
}

.enhanced-consensus-labels {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #333;
  margin-top: 8px;
}

.enhanced-consensus-label {
  flex: 1;
  text-align: center;
}

.enhanced-rating-info {
  margin-top: 10px;
  font-size: 1.1rem;
}

/* Enhanced Target Price Card */
.target-price-card {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to top right, #ffffff, #f5f5f5);
}

.target-price-title {
  background-color: #48ab95;
  color: white;
  padding: 16px;
  border-bottom: 2px solid #004d40;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}

.target-price-icon {
  margin-right: 8px;
  font-size: 1.5rem;
}

.target-price-text {
  padding: 16px;
}

.target-price-table {
  width: 100%;
  border-collapse: collapse;
}

.target-price-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.table-label {
  font-weight: bold;
}

.positive {
  color: #48ab95; /* Positive growth color */
}

.negative {
  color: #F08080; /* Negative growth color */
}


.positiveConsensus {
  background-color: #2e7d32;
}

.negativeConsensus {
  background-color: lightcoral;
}


.price-info {
  margin-top: 16px;
}

.chart {
  width: 100%;
  height: 320px;
}

.text-right {
  text-align: right;
}

.qualityValue {
  color: #f8f9fa;
  font-weight: bold;
}

@media (max-width: 600px) {
  .mainRow {
    flex-direction: column;
  }

  .mainCard,
  .consensusCard,
  .targetPriceCard,
  .radarCard {
    margin-bottom: 20px;
  }
}
/* Enhanced Radar Chart Card */

/* Adjust the chart canvas styling */
.radar-chart {
  width: 100%;
  height: 320px; /* Adjust height as needed */
  border-radius: 10px; /* Rounded corners for the chart */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

</style>



<script>
import axios from 'axios';
import { defineComponent  } from 'vue'
import Chart from 'chart.js/auto';
import { Radar } from 'vue-chartjs';
import 'chartjs-adapter-date-fns';
export default defineComponent({
  name: 'MyDashboard',
  props: {
      company: String,
      selectedCompagny: Array,
    },
    extends: Radar,
    data() {
    return{
      targetPrice: 0,
      analystNumber: 0,
      growthToTarget: 0,
      historicalData: [],

      analystConsensus: 0,
      ratingScore: 0,
      radarData: [],
      setUserlistData: [],
      fetch: false,
      direction: 'horizontal',
      margin: {
      top: 50,
      bottom: 0,
      },   
      threshold1: 10,
      threshold2: 15,
      threshold3: 3,
      threshold4: 0,
      cardItems: [
        { label: "Croissance Free Cash Flow", value: "", thresholdRule: null },
        { label: "Croissance annuelle du chiffre d'affaire", value: "", thresholdRule: null },
        { label: "Dette/ free cashflow", value: "", thresholdRule: "rule3" },
        { label: "Nombre de rachat action", value: "", thresholdRule: "rule4" },
        { label: "Marge nette", value: "", thresholdRule: null },
        { label: "ROE moyen", value: "", thresholdRule: "rule2" },
      ],
    showChart1: true,
    showText: false,
    cardLoading: false,
    error: null,
    lastValue: null,
    percentageDifference: null,
    ratingInfo: {},
    selectedTimeFrame: '5Y',
    isLoading: true,
    isLoading2: false
    }
},
computed: {
  barWidth() {
      return (this.analystConsensus) * 25; // Adjust as necessary
    },

  roundedPercentage() {
      return this.percentageDifference !== null ? this.percentageDifference.toFixed(2) : null;
    },
    percentageColor() {
      return this.percentageDifference !== null
        ? this.percentageDifference >= 0
          ? '#388E3C'
          : 'lightcoral'
        : '#555';
    }
  },
  methods:{
    formatValue(value, suffix = '') {
      if (value === null || value === undefined || isNaN(value) || (Array.isArray(value) && value.length === 0)) {
      return 'N/A';
    }
      return `${value.toFixed(2)}${suffix}`;
    },

    // Format the percentage with a plus sign for positive values
    formatPercentage(percentage) {
      return percentage ? (percentage >= 0 ? '+' : '') + `${percentage}%` : null;
    },
    convertMsToDate(timeStamp) {
    var date = new Date(timeStamp);
    if (isNaN(date.getTime())) {
        console.error("Invalid Date", timeStamp);
        return "Invalid Date";
    }
    return date.toLocaleString('en-GB');
},
  


async renderChart(data) {
        if (!data) {
            console.error("Error: 'data' is undefined.");
            return;
        }

        this.isLoading = true; // Start loading

        // Simulate loading delay (you can remove this line in actual implementation)
        await new Promise(resolve => setTimeout(resolve, 1000));

        if (!this.$refs.myChart) {
            console.error("Canvas element is not available yet.");
            this.isLoading = false; // Stop loading
            return;
        }

        const ctx = this.$refs.myChart.getContext('2d');
        const prices = data.ValueByTime.map(entry => entry.price);
        this.lastValue = prices[prices.length - 1];

        if (prices.length > 1) {
            const firstValue = prices[0];
            this.percentageDifference = ((this.lastValue - firstValue) / firstValue) * 100;
        } else {
            // If only one value, set percentage difference to null
            this.percentageDifference = null;
        }
        const tooltipCallback = (context) => {
            // Custom tooltip function to display percentage relative to the first value
            const firstValue = prices[0];
            const currentValue = context.dataset.data[context.dataIndex];
            const percentageRelativeToFirst = ((currentValue - firstValue) / firstValue) * 100;
            return `${context.dataset.label}: ${currentValue} (${percentageRelativeToFirst.toFixed(2)}%)`;
        };

        if (this.myChart) {
            this.myChart.destroy();
        }

        this.myChart = new Chart(ctx, {
            data: {
                labels: data.Xaxis,
                datasets: [
                    {
                        type: 'line',
                        label: this.company,
                        borderColor: this.percentageDifference >= 0 ? '#2E7D32' : 'lightcoral',
                        borderWidth: 1,
                        data: prices,
                        yAxisID: 'price',
                        fill: false,
                    },
                ],
            },
            options: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: tooltipCallback, // Use the custom tooltip callback
                        },
                    },
                },
                width: 100,
                height: 500,
                scales: {
                    x: {
                        display: false,
                        type: 'category', // Use 'category' for a categorical x-axis
                        labels: data.ValueByTime.map(entry => entry.time), // Use formattedTime as x-axis labels
                    },
                },
            },
        });
  
        this.isLoading = false; // Stop loading
    },

    renderLinearChart(data) {
  const ctx = this.$refs.myChart2.getContext('2d');
  if (this.myChart2) {
    this.myChart2.destroy();
  }

  const reducedData = {
    date: data.date,
    cours_cumulatifs_simules: data.cours_cumulatifs_simules,
    linear_regression_line: data.linear_regression_line,
    std_dev_line_1: data.std_dev_line_1,
    std_dev_line_neg_1: data.std_dev_line_neg_1,
    std_dev_line_2: data.std_dev_line_2,
    std_dev_line_neg_2: data.std_dev_line_neg_2,
  };

  this.myChart2 = new Chart(ctx, {
    type: 'line',
    data: {
      labels: reducedData.date,
      datasets: [
        {
          label: 'Cours Action Ajusté',
          borderColor: '#2E7D32',
          backgroundColor: 'transparent',
          pointRadius: 5,
          data: reducedData.cours_cumulatifs_simules,
        },
        {
          label: 'Régression Linéaire',
          borderColor: 'red',
          backgroundColor: 'transparent',
          pointRadius: 0,
          data: reducedData.linear_regression_line,
        },
        {
          label: '+1 Écart-type',
          borderColor: '#90e0ef',
          backgroundColor: 'transparent',
          pointRadius: 0,
          borderDash: [5, 5],
          data: reducedData.std_dev_line_1,
        },
        {
          label: '-1 Écart-type',
          borderColor: '#90e0ef',
          backgroundColor: 'transparent',
          pointRadius: 0,
          borderDash: [5, 5],
          data: reducedData.std_dev_line_neg_1,
        },
        {
          label: '+2 Écart-types',
          borderColor: 'orange',
          backgroundColor: 'transparent',
          pointRadius: 0,
          borderDash: [5, 5],
          data: reducedData.std_dev_line_2,
        },
        {
          label: '-2 Écart-types',
          borderColor: 'orange',
          backgroundColor: 'transparent',
          pointRadius: 0,
          borderDash: [5, 5],
          data: reducedData.std_dev_line_neg_2,
        },
      ],
    },
    options: {
      maintainAspectRatio: false, 
      responsive: true,
      width: 400,
      height: 300, 
      scales: {
        x: {
          display: false,
          type: 'category',
          labels: reducedData.date,
        },
      },
    },
  });
},
renderRadarChart() {
  this.$nextTick(() => {
    const radarChartRef = this.$refs.radarChart;
    if (radarChartRef) {
      const ctx = radarChartRef.getContext('2d');
      if (ctx) {
        // Convert the string values to numeric values
        const numericRadarData = this.radarData.map(score => {
          const [numerator, denominator] = score.split('/').map(Number);
          return (numerator / denominator) * 10; // Normalize to a scale of 0-10
        });

        // Calculate the mean value
        const meanValue = numericRadarData.reduce((sum, value) => sum + value, 0) / numericRadarData.length;

        // Determine color based on mean value
        const isGood = meanValue >= 6.5 ; // Threshold for "good"
        const backgroundColor = isGood ? 'rgba(72, 171, 149, 0.4)' : 'rgba(240, 128, 128, 0.4)'; // Green or Light Coral
        const borderColor = isGood ? '#48ab95' : 'lightcoral'; // Green or Light Coral
        const pointBackgroundColor = isGood ? '#48ab95' : 'lightcoral'; // Green or Light Coral
        const pointHoverBorderColor = isGood ? '#48ab95' : 'lightcoral'; // Green or Light Coral

        if (this.radarChart) {
          this.radarChart.destroy();
        }

        this.radarChart = new Chart(ctx, {
          type: 'radar',
          data: {
            labels: ['Financial', 'Valuation', 'Growth', 'Profitability', 'Momentum'],
            datasets: [
              {
                label: 'Scores',
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                pointBackgroundColor: pointBackgroundColor,
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: pointHoverBorderColor,
                data: numericRadarData // Use the converted numeric data
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false // Hide legend for cleaner look
              },
              tooltip: {
                backgroundColor: '#004d40', // Dark teal background
                titleColor: '#fff',
                bodyColor: '#fff',
                borderColor: '#48ab95',
                borderWidth: 1,
                callbacks: {
                  label: function(context) {
                    return `${context.label}: ${context.raw.toFixed(2)}`; // Display value with two decimals
                  }
                }
              }
            },
            scales: {
              r: {
                ticks: {
                  display: true, // Show scale numbers for context
                  color: '#004d40', // Dark teal for scale numbers
                  font: {
                    size: 14 // Slightly larger font size
                  }
                },
                grid: {
                  color: '#BBDEFB', // Light blue grid lines
                  lineWidth: 1,
                  borderDash: [5, 5] // Dashed grid lines for a modern look
                }
              }
            },
            elements: {
              line: {
                tension: 0.3, // Smooth curve for line
                borderWidth: 2 // Thicker border for better visibility
              }
            }
          }
        });
      } else {
        console.error("Canvas context for radar chart is not available.");
      }
    } else {
      console.error("Canvas element for radar chart is not available.");
    }
  });
},
getAllData(data) {
  const combinedData = [];
  const formattedTime = [];
  const volumesByPrice = {};

  // Iterate over each company in the data object
  for (const company in data) {
    const dictValue = data[company];

    if (dictValue && dictValue.Close && Object.keys(dictValue.Close).length !== 0) {
      const closeValues = Object.values(dictValue.Close);
      const volumeValues = Object.values(dictValue.Volume);

      for (let i = 0; i < closeValues.length; i++) {
        const time = this.convertMsToDate(Object.keys(dictValue.Close)[i]);
        const price = closeValues[i];
        const volume = volumeValues[i] / 10000; // Assuming volume normalization

        if (!formattedTime.includes(this.formatTime(time))) {
          formattedTime.push(this.formatTime(time));
        }

        const dataPoint = { time, price, volume };
        combinedData.push(dataPoint);

        if (!volumesByPrice[price]) {
          volumesByPrice[price] = 0;
        }
        volumesByPrice[price] += volume;
      }
    }
  }

  return {
    ValueByTime: combinedData,
    VolumeByPrice: volumesByPrice,
    Xaxis: formattedTime
  };
},

getData(dictValue) {
  try {

  if (JSON.parse(JSON.stringify(dictValue)).length !== 0) {
    if (JSON.parse(JSON.stringify(dictValue.Close)).length !== 0) {
      const combinedData = [];
      const formattedTime = [];
      for (let i = 0; i < Object.values(dictValue.Close).length; i++) {
        const time = this.convertMsToDate(Object.keys(dictValue.Close)[i]);
        const price = Object.values(dictValue.Close)[i];
        const volume = Object.values(dictValue.Volume)[i] /10000 ;
        if (!formattedTime.includes(this.formatTime(time))) {
          formattedTime.push(this.formatTime(time));
        }
        const dataPoint = { time, price, volume };
        combinedData.push(dataPoint);
      }

      // Group volumes by price and calculate the sum
      const volumesByPrice = {};
      for (const dataPoint of combinedData) {
        const { price, volume } = dataPoint;

        if (!volumesByPrice[price]) {
          volumesByPrice[price] = 0;
        }

        volumesByPrice[price] += volume;
      }
      return {
        ValueByTime: combinedData,
        VolumeByPrice: volumesByPrice,
        Xaxis: formattedTime
      };
    }
  }
this.error = null;
} catch (error) {
      console.error("Error rendering chart: ", error);
      // Set the error message
      this.error = "Sorry an unexpected error occurs on, please retry";
    }
},

  formatTime(ms) {
    const dateObject = new Date(ms);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear();
    return `${month}/${year}`;
  },

  getAverage(dictValue){
    if(JSON.parse(JSON.stringify(dictValue)).length != 0){
      var listOfValue = Object.values(dictValue.Dividends)
      var stringToInt=listOfValue.map(x=>+x)
      var average = this.add(stringToInt)/listOfValue.length;
      return Math.round(average * 10) / 10
    }
  },

  add(arr) {
    return arr.reduce((a, b) => a + b, 0);
    },
      
    clearLocalStorageByKeyPattern(pattern, numberOfDay = 1, errorMessage = "error") {
      try {
        const expirationTime = 24 * 60 * 60 * 1000 * numberOfDay; // 1 day
        const currentTime = new Date().getTime();
        for (let key in localStorage) {
          if (key.includes(pattern)) {
            const cachedData = JSON.parse(localStorage.getItem(key));
            // Check if the cached data has expired
            if (currentTime - cachedData.timestamp >= expirationTime 
            || (errorMessage && cachedData.data && cachedData.data.error && cachedData.data.error.includes(errorMessage))){
              localStorage.removeItem(key);
              (`Key "${key}" removed from local storage.`);
            }
          }
        }
      } catch (error) {
        this.handleError(errorMessage || "Failed to clear local storage", error);
      }
},

async fetchAllSelected() {
  let startDate = new Date();
  startDate.setDate(startDate.getDate() - 5);
  const formattedStartDate = `${startDate.getFullYear()}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getDate().toString().padStart(2, '0')}`;
  
  const selectedCompanies = this.selectedCompanies.join(',');

  try {
    const response = await axios.get(`https://api.foliosentry.com/api/searchData?start_date=${formattedStartDate}&selectedCompagny=${selectedCompanies}`);
    this.setUserlistData = response.data;
    // Assuming this.getAllData() processes all companies' data together
    const combinedData = this.getAllData(this.setUserlistData);

    this.$nextTick(() => {
      this.renderChart(combinedData);
    });
  } catch (error) {
    console.error('Error fetching data:', error);
    this.isLoading = false; // Update isLoading to false in case of error
  }
},

async fetchData(timeFrame) {
  if (!this.showChart1) {
    this.showChart1 = !this.showChart1;
  }
    this.isLoading = true;
    let startDate;
    switch (timeFrame) {
      case '5d':
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 5);
        break;
      case '1M':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        break;
        case '3M':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case 'YTD':
        startDate = new Date(new Date().getFullYear(), 0, 1);
        break;
      case '1Y':
        startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
      case '5Y':
        startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 5);
        break;
      default:
        startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
    }

    const formattedStartDate = `${startDate.getFullYear()}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getDate().toString().padStart(2, '0')}`;
      axios
  .get('https://api.foliosentry.com/api/searchData?start_date=' + formattedStartDate + "&selectedCompagny=" + this.company)
  .then(response => {
this.setUserlistData =  response.data ;
    this.$nextTick(() => {
      this.renderChart(this.getData(this.setUserlistData[this.company]));
      this.isLoading = false; // Update isLoading to false after successful data fetching
    });
  })
  .catch(error => {
    console.error('Error fetching data:', error);
    this.isLoading = false; // Update isLoading to false in case of error
  });
},

fetchLinearData() {
    axios
      .get('https://api.foliosentry.com/api/linear_regression?company=' + this.company)
      .then(response => {
        this.$nextTick(() => {
          this.renderLinearChart(response.data);
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  
},

  toggleChart() {
      this.isLoading2 = true
      this.showChart1 = !this.showChart1;
      setTimeout(() => {
      if (this.showChart1) {
        this.fetchData();
      } else {  
        this.fetchLinearData();
      }
      this.isLoading2 = !this.isLoading2

    }, 2000);
    },

  getColor(value, thresholdRule) {
      const numericValue = parseFloat(value);
      switch (thresholdRule) {
        case "rule2":
          return numericValue >= this.threshold2 ? "#48ab95" : "#F08080";
        case "rule3":
          return numericValue <= this.threshold3 ? "#48ab95" : "#F08080";
        case "rule4":
          return numericValue <= this.threshold4 ? "#48ab95" : "#F08080";
        default:
          return numericValue >= this.threshold1 ? "#48ab95 " : "#F08080";
      }
    },

    loadQualityMetrics() {
      const storageKey = `qualityMetricsData_${this.company}`;
      this.clearLocalStorageByKeyPattern(storageKey, 10);
      const cachedData = localStorage.getItem(storageKey);
      if (cachedData) {
        this.updateCardItems(JSON.parse(cachedData));
      } else {
        axios.get(`https://api.foliosentry.com/api/qualityIndicator?company=${this.company}`)
          .then(response => {
            const data = response.data;
            this.updateCardItems(data);
            localStorage.setItem(storageKey, JSON.stringify(data));
          })
          .catch(error => {
            console.error("Error fetching data:", error);
          });
      }
    },

    updateCardItems(apiData) {
      this.cardItems = [
      { label: "Croissance Free Cash Flow", value: this.formatValue(apiData.avg_cash_flow_growth, '%'), thresholdRule: null },
      { label: "Croissance annuelle du chiffre d'affaire", value: this.formatValue(apiData.total_revenue_growth, '%'), thresholdRule: null },
      { label: "Dette/ free cashflow", value: this.formatValue(apiData.ratio_debt_to_equity_to_fcf_per_share), thresholdRule: "rule3" },
      { label: "Nombre de rachat action", value: this.formatValue(apiData.avg_commonStock_growth / 1000), thresholdRule: "rule4" },
      { label: "Marge nette", value: this.formatValue(apiData.avg_net_income_ratio, '%'), thresholdRule: null },
      { label: "ROE moyen", value: this.formatValue(apiData.avg_roe, '%'), thresholdRule: "rule2" },
    ];
    },

    fetchPerformanceData() {
      // Fetch the performance data from the API
      axios.get(`https://api.foliosentry.com/api/performance?company=${this.company}`)
        .then(response => {
          // Parse the API response
          const performanceData = response.data;
          
          // Map the performance data to the historicalData array format
          this.historicalData = [
            { period: '1 M', variation: performanceData['1 month'] },
            { period: '3 M', variation: performanceData['3 months'] },
            { period: '6 M', variation: performanceData['6 months'] },
            { period: '1 Y', variation: performanceData['1 year'] },
            { period: '3 Y', variation: performanceData['3 years'] },
            { period: '5 Y', variation: performanceData['5 years'] }
          ];
        })
        .catch(error => {
          console.error('Error fetching performance data:', error);
        });
    },
    fetchSummary() {
    fetch('https://api.foliosentry.com/api/summary?company=' + this.company)
      .then(response => response.json())
      .then(data => {
        // Set the variables in data based on the API response
        this.targetPrice = data['Target Mean Price'];
        this.analystNumber = data['Number of Analyst Opinions'];
        this.growthToTarget = ((data['Target Mean Price'] - data['Previous Close']) / data['Previous Close']) * 100;
        this.ratingScore = data['Global Rank'];
        this.analystConsensus = data['Recommendation Mean'];
        this.radarData = [
          data['Financial Strength'],
          data['GF Value Rank'],
          data['Growth Rank'],
          data['Profitability Rank'],
          data['Momentum Rank']
        ];

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },
  },
  created() {
    this.fetchSummary()
    this.fetchPerformanceData();
  },

  mounted() {
  this.fetchData("5Y")
  .then(() => {
      this.loadQualityMetrics();
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });

},
watch: {
    isLoading(newVal) {
        if (!newVal) {
            this.$nextTick(() => {
                // Ensure the radar chart canvas element is available
                if (this.$refs.radarChart) {
                    // Call renderRadarChart() only if radarData is available
                    if (this.radarData && this.radarData.length > 0) {
                        this.renderRadarChart();
                    } else {
                        console.error("Radar data is not available or empty.");
                    }
                } else {
                    console.error("Canvas element for radar chart is not available.");
                }
            });
        }
    },
    radarData: {
        handler(newVal) {
            // Call renderRadarChart() when radarData is set and not empty
            if (newVal && newVal.length > 0) {
                this.renderRadarChart();
            }
        },
        immediate: true  // Execute the handler when the component is created
    }
},


  //mounted () {
  /*  return new Promise(resolve => {
        setTimeout(() => { 
        this.loaded = false
          const path = 'https://api.foliosentry.com/api/listAction'
          axios
          .get(path)
          .then(rep  => (this.chartData = rep.data))
          resolve()
      })
          this.loaded = true
    })
  }*/
});
</script>
