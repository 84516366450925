

<template>
      <div>
        <MyNavPanel
        :selectedCompagny="companyList"
        @selected-company="currentCompagny"
      />
        <div v-for="(item, index) in companyList" :key="index">
          <CompagnyData :company="item"/>  
        </div>
      </div>
</template>

<script>
import MyNavPanel from '../components/Navpanel.vue'
import CompagnyData from '../components/CompagnyData.vue'

export default {
  name: 'FinancialData',
  data() {
    return {
      companyList: [],
      listSelected: [], // Initialize listSelected
    }
  },
  methods: {
    currentCompagny(value) {
      this.companyList = value
    },
    currentPotential(value) {
      this.listSelected = value
    }
  },
  components: {
    MyNavPanel,
    CompagnyData,
  },
};
</script>


