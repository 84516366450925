

<template>
  <div>
    <MyNavPanel :selectedCompagny="companyList" @selected-company="currentCompagny"/>
  </div>

  <div v-for="(item, index) in companyList" :key="index">
    <template v-if="companyList.includes(item)">
      <Suspense :key="'dashboard-' + item">
        <MyDashboard :company="item" :selectedCompagny="companyList"/> 
      </Suspense>
    </template>

</div>

</template>


<script>
import MyNavPanel from '../components/Navpanel.vue'
import MyDashboard from '../components/Dashboard.vue'

export default {
  name: 'MyHome',
  data() {
    return{
      companyList: [],
    }
  },
  methods: {
    currentCompagny (value) {
      this.companyList = value
    }
  },
  components: {
    MyNavPanel,
    MyDashboard,
  },
};
</script>


