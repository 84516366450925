<template>
  <div>
    <MyNavPanel :selectedCompagny="companyList" @selected-company="currentCompagny"/>

    <v-container fluid class="container">
      <v-row justify="center" class="no-gutters">
        <!-- Tableur component -->
        <v-col cols="12" sm="3" md="3" lg="3">
          <div class="scrollable-container">
            <div class="tableur-container">
              <div v-for="(company, index) in companyList" :key="index">
                <myValorization :company="company"/>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="8" md="8" lg="8">
          <div class="scrollable-container">

            <v-card class="mixed-graph-card">
              <v-card-title class="perf_title">
              Performance Calculation
            </v-card-title>
              <MixedGraph :companyList="companyList" />
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MyNavPanel from '../components/Navpanel.vue'
import MyValorization from '../components/Valorization.vue'
import MixedGraph from '../components/MixedGraph.vue'

export default {
  name: "PerformanceView",
  data() {
    return {
      companyList: [],
    }
  },
  methods: {
    currentCompagny(value) {
      this.companyList = value
    },
  },
  components: {
    MyNavPanel,
    MyValorization,
    MixedGraph,
  },
};
</script>

<style>
.container {
  margin-top: 3%;
}

.scrollable-container {
  height: calc(100vh - 18vh); /* Adjust based on header height */
  overflow-y: auto;

}

.mixed-graph-card {
  background-color: #ffffff;
  height: 80vh;
  overflow-y: auto;

  margin: 10px;
  margin-left: 5%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.tableur-container {
  background-color: #ffffff;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.perf_title {
  padding: 16px;
  color: #004d40;
  background-color: #BBDEFB;
}
</style>
