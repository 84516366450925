// vuetify.js
import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css'; // Ensure icons are available
import 'vuetify/styles';

const vuetify = createVuetify({
  // Add any customization if necessary
});

export default vuetify;
