<template>
  <v-app id="app">
    <v-main class="home">
      <MyHeader />
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import MyHeader from './components/Header.vue';

export default {
  name: 'App',
  components: {
    MyHeader,
  },
};
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f8f9fa;
}

::-webkit-scrollbar {
  width: 3px;
  background-color: #f8f9fa;
}

::-webkit-scrollbar-thumb {
  height: 2%;
  background-color: #f8f9fa;
}

.home {
  height: 100%;
  width: 100%;
  background-color: #f8f9fa;
  color: #004d40;
}
</style>
