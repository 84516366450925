<template>
  <div class="performance-graph">
    <div class="toggle-container">
      <button class="chevron-button" @click="toggleVisibility">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="chevron-icon"
        >
          <path :d="isVisible ? 'M19 15l-7-7-7 7' : 'M19 9l-7 7-7-7'" />
        </svg>
      </button>
    </div>

    <div v-if="isVisible" class="performance-graph__controls">
      <div class="performance-graph__control-group">
        <label for="date-picker" class="performance-graph__label">Select Month:</label>
        <input
          id="date-picker"
          type="month"
          v-model="selectedDate"
          class="performance-graph__date-picker"
        />
      </div>
      <div class="performance-graph__control-group">
        <label for="investment-input" class="performance-graph__label">Investment Amount:</label>
        <input
          id="investment-input"
          type="number"
          v-model="investmentAmount"
          placeholder="Enter amount"
          class="performance-graph__investment-input"
        />
      </div>
    </div>

    <div v-if="isLoading" class="loading-overlay">
      <v-progress-circular indeterminate color="#004D40" />
    </div>

    <div :class="{'chart-container': true, 'full-height': !isVisible}">
      <canvas ref="chart" class="performance-graph__chart"></canvas>
    </div>

    <div class="performance-graph__table-container">
      <table class="performance-graph__table">
        <thead>
          <tr>
            <th>Company</th>
            <th>Date</th>
            <th>Dividends</th>
            <th>Gain</th>
            <th>Shares</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in tableData" :key="entry.date + entry.company">
            <td>{{ entry.company }}</td>
            <td>{{ entry.date }}</td>
            <td>{{ entry.resume.dividends }}</td>
            <td>{{ entry.resume.gain }}</td>
            <td>{{ entry.resume.nbActions }}</td>
            <td>{{ entry.resume.percentage }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<style>
.performance-graph {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-radius: 12px;
  overflow-y: auto;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  position: relative;
  padding: 16px;
  text-align: center;
}

.toggle-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.chevron-button {
  background-color: #48ab95;
  color: #f8f9fa;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.chevron-button:hover {
  background-color: #00695c;
  transform: scale(1.1);
}

.chevron-icon {
  transition: transform 0.3s ease;
}

.performance-graph__controls {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

.performance-graph__control-group {
  margin-bottom: 16px;
}

.performance-graph__label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #004D40;
}

.performance-graph__date-picker,
.performance-graph__investment-input {
  border: 1px solid #f8f9fa;
  border-radius: 4px;
  padding: 8px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.performance-graph__investment-input {
  max-width: 250px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.chart-container {
  width: 100%;
  transition: height 0.3s ease;
  flex: 1;
}

.full-height {
  height: 100%;
}

.performance-graph__chart {
  width: 100%;
  height: 485px !important;
}

@media (max-width: 768px) {
  .performance-graph__chart {
    height: 360px !important;
  }
}

.performance-graph__table-container {
  margin-top: 2%;
  padding: 0 8px;
  overflow-x: auto; /* Enable horizontal scroll */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.performance-graph__table {
  width: 100%;
  border-collapse: collapse;
  background-color: #f8f9fa;
  min-width: 600px; /* Set a min-width for responsiveness */
}

.performance-graph__table th,
.performance-graph__table td {
  padding: 12px;
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
}

.performance-graph__table th {
  background-color: #004D40;
  color: #f8f9fa;
  font-weight: bold;
}

.performance-graph__table td {
  border: 1px solid #f8f9fa;
}

@media (max-width: 768px) {
  .performance-graph__controls {
    flex-direction: column;
  }

  .performance-graph__control-group {
    margin-bottom: 12px;
  }

  .performance-graph__investment-input {
    width: 100%;
  }
}

</style>
<script>
import { Chart, registerables } from 'chart.js';
import axios from 'axios';

// Register Chart.js components
Chart.register(...registerables);

export default {
  props: {
    companyList: {
      type: Array,
      required: true,
    },
  },
  data() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    currentDate.setFullYear(currentYear, 0);

    return {
      chart: null,
      chartData: {
        labels: [],
        datasets: [],
      },
      isLoading: false,
      checkedCompanies: {},
      tableData: [],
      selectedDate: currentDate.toISOString().slice(0, 7),
      investmentAmount: 1000,
      isVisible: true,
      isPhoneView: window.matchMedia('(max-width: 768px)').matches,
    };
  },
  watch: {
    companyList: {
      handler() {
        this.updateGraph();
      },
      deep: true,
    },
    selectedDate: 'updateGraph',
    investmentAmount: 'updateGraph',
    isPhoneView() {
      // Update the chart when the screen size changes
      this.updateGraph();
    },
  },
  mounted() {
    this.createChart();
    this.updateGraph();

    // Listen for screen size changes
    window.matchMedia('(max-width: 768px)').addEventListener('change', this.handleResize);
  },
  beforeUnmount() {
    // Cleanup event listener on component destroy
    window.matchMedia('(max-width: 768px)').removeEventListener('change', this.handleResize);
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    handleResize(event) {
      this.isPhoneView = event.matches;
    },
    createChart() {
      if (!this.$refs.chart) {
        console.error("Canvas element is not available yet.");
        return;
      }
      if (this.chart) {
        this.chart.destroy();
      }
      const ctx = this.$refs.chart.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: this.chartData,
        options: {
          responsive: true,
          scales: {
            x: {
              display: false, // Hide x-axis labels
              title: {
                display: false, // Hide x-axis title
              },
            },
            y: {
              beginAtZero: true,
              display: false, // Hide y-axis labels
              title: {
                display: false, // Hide y-axis title
              },
            },
          },
          plugins: {
            legend: {
              display: false, // Hide legend
            },
            tooltip: {
              enabled: true, // Enable tooltips
            callbacks: {
              label: function (context) {
                const companyName = context.dataset.label; // Company name
                const price = context.raw; // Price value
                return `${companyName}: $${price.toFixed(2)}`; // Customize the tooltip content
              },
            }
            },
          },
        },
      });
    },
    async updateGraph() {
      this.isLoading = true;

      this.checkedCompanies = {};
      this.chartData = {
        labels: [],
        datasets: [],
      };
      this.tableData = [];

      if (this.companyList.length === 0) {
        this.isLoading = false;
        return;
      }

      const companyDates = this.companyList.map((company) => {
        const date = new Date(this.selectedDate + '-01');
        const formattedDate = date.toISOString().slice(0, 10).replace(/-/g, '/');
        return { company, date: formattedDate };
      });

      const paramsArray = companyDates.map(({ company, date }) => `company=${encodeURIComponent(company)}&date=${date}`);
      paramsArray.push(`investmentAmount=${this.investmentAmount}`);
      const queryString = paramsArray.join('&');
      const url = `https://api.foliosentry.com/api/custoPerformance?${queryString}`;

      try {
        const response = await axios.get(url);
        this.tableData = response.data;

        response.data.forEach((data) => {
          const dateFormatted = data.date.replace(/\//g, '-');

          if (!this.chartData.labels.includes(dateFormatted)) {
            this.chartData.labels.push(dateFormatted);
          }

          if (!this.checkedCompanies[data.company]) {
            this.checkedCompanies[data.company] = true;
            this.chartData.datasets.push({
              label: data.company,
              data: Array(this.chartData.labels.length).fill(0),
              backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.5)`,
            });
          }

          const datasetIndex = this.chartData.datasets.findIndex(
            (dataset) => dataset.label === data.company
          );
          const dateIndex = this.chartData.labels.findIndex(
            (label) => label === dateFormatted
          );
          this.chartData.datasets[datasetIndex].data[dateIndex] = data.resume.totalArgent;
        });

        this.createChart();
        this.isLoading = false;

      } catch (error) {
        this.isLoading = false;

        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
        } else if (error.request) {
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
      }
    },
  },
};
</script>
