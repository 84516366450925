<template>
  <div>
      <div>
        <MyNavPanel
        :selectedCompagny="companyList"
        @selected-company="currentCompagny"
      />
        <div v-for="(item, index) in companyList" :key="index">
          <CompagnySummary :company="item" />  
        </div>
      </div>
  </div>
</template>

<script>
import MyNavPanel from '../components/Navpanel.vue'
import CompagnySummary from '../components/CompagnySummary.vue'

export default {
  name: "InvestorSummary",
  data() {
    return {
      companyList: [],
    }
  },
  methods: {
    currentCompagny(value) {
      this.companyList = value
    }
  },
  components: {
    MyNavPanel,
    CompagnySummary
  },
};
</script>
