<script>
import axios from 'axios';

export default {
  name: "NavPanel",
  props: ['selectedCompagny', 'potentialSelection'],
  data() {
    return {
      drawer: true,
      graphContent: "",
      companyList: [],
      recentList: ["back down"],
      favoritesList: [],
      ourSelectionList: [],
      listSelected: [],
      currentlySelected: [],
      allCompagny: [],
      searchResults: [],
      searchText: "",
      toggles: {},
      currentList: 1,
      activeButton: null,
      hoveredButton: null,
      editableNumbers: {},
      itemData: {},
      isEditing: {},
      maxSelection: "",
    }
  },
  beforeMount() {
    this.activeButton = 'recent';
    this.fetchData();

  },
  created() {
    this.initializeToggles();
    this.loadEditableNumbers();
  },
  computed: {
    toggleBtnClass() {
      return {
        'toggle-btn-container': true,
        'active': this.drawer,
      }
    },
    activeToggleCountRecent() {
      return this.calculateActiveToggleCount(this.recentList);
    },
    activeToggleCountPortfolio() {
      return this.calculateActiveToggleCount(this.favoritesList);
    },
    activeToggleCountWatchlist() {
      return this.calculateActiveToggleCount(this.ourSelectionList);
    },
  },
  methods: {
    toggleNav() {
      this.drawer = !this.drawer; // Toggle the visibility of the nav panel
    },
    calculateActiveToggleCount(list) {
      if (!list) {
        return 0;
      }
      return list.filter(key => this.toggles[key] && list.includes(key)).length;
    },
    fetchData() {
      const userLoggedIn = false; // Set to true if the user is logged in
      axios.get('https://api.foliosentry.com/api/listAction')
        .then(response => {
          this.allCompagny = response.data.content.allAction;
          localStorage.setItem('allCompagny', JSON.stringify(this.allCompagny));

          if (!userLoggedIn) {
            const cachedRecentList = localStorage.getItem('recentList');
            const cachedFavoritesList = localStorage.getItem('favoritesList');
            const cachedOurSelectionList = localStorage.getItem('ourSelectionList');
            const cachedAllCompagny = localStorage.getItem('allCompagny');
            this.recentList = JSON.parse(cachedRecentList);
            this.favoritesList = JSON.parse(cachedFavoritesList);
            this.ourSelectionList = JSON.parse(cachedOurSelectionList);
            this.allCompagny = JSON.parse(cachedAllCompagny);
          } else {
            this.recentList = response.data.content.recent;
            this.favoritesList = response.data.content.favorites;
            this.ourSelectionList = response.data.content.ourSelection;
            // Save lists to frontend cache or local storage for future use
            localStorage.setItem('recentList', JSON.stringify(this.recentList));
            localStorage.setItem('favoritesList', JSON.stringify(this.favoritesList));
            localStorage.setItem('ourSelectionList', JSON.stringify(this.ourSelectionList));
          }
          this.listSelected = this.recentList;
          //this.fetchDataForSelectedItems(); // Fetch data for selected items
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          // Handle error - show error message to the user or retry the request
        });
    },
    initializeToggles() {
  const allItems = [...this.recentList, ...this.favoritesList, ...this.ourSelectionList];
  if (!localStorage.getItem('toggleState')) {
    this.toggles = {};
    for (const item of allItems) {
      this.toggles[item] = false;
    }
  } else {
    // Retrieve toggle state from local storage
    this.toggles = JSON.parse(localStorage.getItem('toggleState'));
  }
  for (const item in this.toggles) {
    if (this.companyList.includes(item)) {
      this.toggles[item] = true; // Set toggle state to true for selected items
      this.select(item); // Trigger select function for each selected item
    }
    if (this.toggles[item]) {
      this.companyList.push(item);
      this.select(item, true);
    }
  }
},

select(value, init = false) {
  if (!init) {
    if (this.companyList.includes(value)) {
      this.companyList = this.companyList.filter(item => item !== value);
      this.toggles[value] = false;
      this.maxSelection = ""; // Clear max selection message
    } else {
      if (this.companyList.length < 3) {
        this.companyList.push(value);

        this.toggles[value] = true;
        this.maxSelection = ""; // Clear max selection message
      } else {
        this.maxSelection = "Sorry, you reached the maximum of 3 selections.";
      }
    }
  }

  delete this.toggles['back down'];
  localStorage.setItem('toggleState', JSON.stringify(this.toggles));
  this.$emit('selected-company', this.companyList);
},

    clearInput() {
      this.searchText = "";
      this.searchResults = [];
    },
    updateRecent(event) {
  let value = event;
  const userLoggedIn = false;
  
  // If the event is not in searchResults, fallback to the first item in searchResults
  if (!this.searchResults.includes(event) && this.searchResults.length > 0) {
    value = this.searchResults[0];
  }
  // Proceed if user is not logged in
  if (!userLoggedIn) {
    if (!this.recentList) {
      this.recentList = [];
    }
    // Check if value is not already in recentList
    if (!this.recentList.includes(value)) {
      // Remove the first element with a false value in toggles, or the second element if the first is true
      let removedIndex = -1;
      for (let i = 0; i < this.recentList.length; i++) {
        const currentItem = this.recentList[i];
        if (this.toggles[currentItem] == false || this.toggles[currentItem] === undefined) {
          removedIndex = i;
          break; // Found false value, remove this one
        } else if (this.toggles[currentItem] === true && removedIndex === -1) {
          removedIndex = i + 1; // Found true value, remove next one if not removed already
        }
      }
      
      // Remove the element if index found and not -1
      if (this.recentList.length > 4) {
        this.recentList.splice(removedIndex, 1); // Remove the first element if exceeding 5
      }
      // Add the new value to recentList
      this.recentList.push(value);
      localStorage.setItem('recentList', JSON.stringify(this.recentList));
      // Limit recentList to maximum 5 elements
        }
        
        if (!this.listSelected) {
          this.listSelected = [];
        }
        if (!this.listSelected.includes(value)) {
          this.listSelected.push(value);
          localStorage.setItem('listSelected', JSON.stringify(this.listSelected));

          if (this.currentList == 2) {
            if (!this.favoritesList) {
                this.favoritesList = [];
                this.favoritesList.push(value)
              }
            localStorage.setItem('favoritesList', JSON.stringify(this.listSelected));
          }
          if (this.currentList == 3) {
            if (!this.ourSelectionList) {
                this.ourSelectionList = [];
                this.ourSelectionList.push(value);

              }
            localStorage.setItem('ourSelectionList', JSON.stringify(this.listSelected));
          }
          const path = 'https://api.foliosentry.com/api/listAction?selectedList=' + this.listSelected;
          this.clearInput();
          axios.post(path, this.recentList);
        }
      } else {
        const path = 'https://api.foliosentry.com/api/listAction?selectedList=' + this.listSelected;
        this.clearInput();
        axios.post(path, this.recentList)
          .then(() => {
            if (!this.listSelected) {
              this.listSelected = [];
            }
            if (!this.listSelected.includes(value)) {
              this.listSelected.push(value);
              localStorage.setItem('listSelected', JSON.stringify(this.listSelected)); // Update the cached version of listSelected
            }
            if (!this.recentList) {
              this.recentList = [];
            }
            if (!this.recentList.includes(value)) {
              this.recentList.push(value);
              localStorage.setItem('recentList', JSON.stringify(this.recentList));
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleInput(value) {
      this.searchText = value;
      if (this.searchText.length > 2) {
        this.searchResults = this.allCompagny.filter((x) => this.searchText && x.toLowerCase().includes(this.searchText.toLowerCase()));
        for (let i = 0; i < this.searchResults.length; i++) {
          if (this.searchText == this.searchResults[i]) {
            return this.searchText;
          }
        }
        return this.searchResults;
      }
    },
    saveEditableNumber(item) {
      localStorage.setItem(`editableNumbers[${item}]`, JSON.stringify(this.editableNumbers[item]));
    },
    loadEditableNumbers() {
      /*for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        const value = localStorage.getItem(key);
      }*/
      for (const key in localStorage) {
        if (key.startsWith('editableNumbers[')) {
          const rowName = key.slice(16, -1);
          this.editableNumbers[rowName] = JSON.parse(localStorage.getItem(key));
        }
      }
    },

    presetList(value) {
      this.activeButton = value;
      if (value == "recent") {
        this.listSelected = this.recentList;
        this.currentList = 1;
      }
      if (value == "portfolio") {
        this.listSelected = this.favoritesList;
        this.currentList = 2;
        this.$emit('potential-company', this.listSelected);
      }
      if (value == "watchlist") {
        this.currentList = 3;
        this.listSelected = this.ourSelectionList;
        this.$emit('potential-company', this.listSelected);
      }
    },



    removeItem(item) {
      const index = this.listSelected.indexOf(item);
      if (index !== -1) {
        this.listSelected.splice(index, 1); // Remove from selected list
        this.companyList = this.companyList.filter(companyItem => companyItem !== item);
        this.toggles[item] = false;
        delete this.toggles[item];
        localStorage.setItem('toggleState', JSON.stringify(this.toggles));
        localStorage.setItem('listSelected', JSON.stringify(this.listSelected)); // Update cached selected list

        if (this.currentList === 1) {
          localStorage.setItem('recentList', JSON.stringify(this.listSelected)); // Update cached recent list
        } else if (this.currentList === 2) {
          localStorage.setItem('favoritesList', JSON.stringify(this.listSelected)); // Update cached favorites list
        } else if (this.currentList === 3) {
          localStorage.setItem('ourSelectionList', JSON.stringify(this.listSelected)); // Update cached ourSelected list
        }
        // Correct the filtering logic

        this.$emit('selected-company', this.companyList);
      }
    },
    toggleEdit(item) {
      if (this.isEditing[item]) {
        this.isEditing[item] = false;
      } else {
        this.isEditing[item] = true;
      }
    },
  },
}

  
</script>
<template>
  <div class="nav">
    <v-navigation-drawer v-model="drawer" class="search">
  <v-autocomplete
    ref="searchBar"
    clearable
    auto-select-first
    :items="searchResults"
    @input="$event => handleInput($event.target.value)"
    @click:clear="clearInput"
    v-on:keyup.enter="updateRecent($event.target.value)"
    hide-no-data
    hide-selected
    placeholder="Search for a company..."
  ></v-autocomplete>

      <div class="scrollable-list">
        <!-- List 1 -->
        <template v-if="currentList === 1">
          <v-list-item v-for="(item, index) in listSelected" :key="index">
            <v-row align="center">
              <v-col>
                <div class="toggle-button" :class="{ 'selected': toggles[item], 'disabled': !toggles[item] && companyList.length >= 3 }" @click="select(item)">
                  <span>{{ item }}</span>
                </div>
              </v-col>
              <v-col cols="auto">
                <button @click="removeItem(item)" class="delete-button">
                  <v-icon>mdi-close</v-icon>
                </button>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-list-item>
        </template>

        <!-- List 2 -->
        <template v-if="currentList === 2">
          <v-list-item v-for="(item, index) in listSelected" :key="index">
            <v-row align="center">
              <v-col>
                <div class="toggle-button" :class="{ 'selected': toggles[item], 'disabled': !toggles[item] && companyList.length >= 3 }" @click="select(item)">
                  <span>{{ item }}</span>
                </div>
              </v-col>
              <v-col cols="auto">
                <button @click="removeItem(item)" class="delete-button">
                  <v-icon>mdi-close</v-icon>
                </button>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-list-item>
        </template>

        <!-- List 3 -->
        <template v-if="currentList === 3">
          <v-list-item v-for="(item, index) in listSelected" :key="index">
            <v-row align="center">
              <v-col>
                <div class="toggle-button" :class="{ 'selected': toggles[item], 'disabled': !toggles[item] && companyList.length >= 3 }" @click="select(item)">
                  <span>{{ item }}</span>
                </div>
              </v-col>
              <v-col cols="auto">
                <button @click="removeItem(item)" class="delete-button">
                  <v-icon>mdi-close</v-icon>
                </button>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-list-item>
        </template>
      </div>

      <v-toolbar class="footer" flat>
        <v-row justify="space-around">
          <div class="historicalButton">
            <div v-if="hoveredButton === 'recent'" class="hover-listName recent-hover-listName">Historical</div>
            <v-btn 
              @click="presetList('recent')" 
              :class="{ 'square-button': true, 'active-button': activeButton === 'recent' }" 
              icon 
              @mouseover="hoveredButton = 'recent'" 
              @mouseleave="hoveredButton = null"
            >
                  <span  v-if="activeToggleCountRecent > 0 && activeButton !== 'recent'" overlap class="notification">{{ activeToggleCountRecent }}</span>
              <v-tooltip bottom>
                <template v-slot:activator>
                  <v-icon :class="{ 'icon': true, 'active-button': activeButton === 'recent' }">mdi-clock</v-icon>
                </template>
              </v-tooltip>
            </v-btn>
          </div>
          <div class="portefolioButton">
            <div v-if="hoveredButton === 'portfolio'" class="hover-listName portfolio-hover-listName">Portfolio</div>
            <v-btn 
              @click="presetList('portfolio')" 
              :class="{ 'square-button': true, 'active-button': activeButton === 'portfolio' }" 
              icon 
              @mouseover="hoveredButton = 'portfolio'" 
              @mouseleave="hoveredButton = null"
            >
                  <span  v-if="activeToggleCountPortfolio > 0 && activeButton !== 'portfolio'" overlap class="notification">{{ activeToggleCountPortfolio }}</span>
              <v-tooltip bottom>
                <template v-slot:activator>
                  <v-icon :class="{ 'icon': true, 'active-button': activeButton === 'portfolio' }">mdi-briefcase</v-icon>
                </template>
              </v-tooltip>
            </v-btn>
          </div>
          <div class="watchlistButton">
            <div v-if="hoveredButton === 'watchlist'" class="hover-listName watchlist-hover-listName">Watchlist</div>
            <v-btn 
              @click="presetList('watchlist')" 
              :class="{ 'square-button': true, 'active-button': activeButton === 'watchlist' }" 
              icon 
              @mouseover="hoveredButton = 'watchlist'" 
              @mouseleave="hoveredButton = null"
            >
                  <span class="notification" v-if="activeToggleCountWatchlist > 0 && activeButton !== 'watchlist'" overlap>{{ activeToggleCountWatchlist }}</span>
              <v-tooltip bottom>
                <template v-slot:activator>
                  <v-icon :class="{ 'icon': true, 'active-button': activeButton === 'watchlist' }">mdi-eye</v-icon>
                </template>
              </v-tooltip>
            </v-btn>
          </div>
        </v-row>
      </v-toolbar>
      <div :class="toggleBtnClass">
      <v-btn @click="toggleNav" class="toggle-btn" icon>
        <v-icon>{{ drawer ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
      </v-btn>
    </div>

    </v-navigation-drawer>
  </div>
</template>

<style>
/* Footer Styles */
.footer {
  background-color: #f8f9fa; /* Light gray background for a modern look */
  color: #333; /* Darker text color for better readability */
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2); /* Deeper shadow for more depth */
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

/* Footer Row */
.footer .v-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* Button Styles */
.footer .square-button {
  border-radius: 50%; /* Circular buttons */
  width: 46px; /* Size of buttons */
  height: 46px;
  background-color: #48ab95 ; /* Primary blue for selected state */
  color: #f8f9fa;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Button Hover Styles */
.footer .square-button:hover {
  color: #f8f9fa;
  background-color: #004d40; /* Darker blue on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Active/Selected Button Styles */
.footer .square-button.active-button {
  background-color: #00695c; /* Modern blue background */
  color: #f8f9fa; /* White icon color */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3); /* Enhanced shadow for active state */
}

/* Hover Text */
.footer .hover-listName {
  position: absolute;
  background-color: #00695c; /* Match button background */
  color: #f8f9fa;
  border-radius: 4px;
  padding: 6px 12px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(-12px);
}

.footer .hover-listName.recent-hover-listName {
  left: 50%;
  transform: translate(-50%, -12px);
}

.footer .hover-listName.portfolio-hover-listName {
  left: 50%;
  transform: translate(-50%, -12px);
}

.footer .hover-listName.watchlist-hover-listName {
  left: 50%;
  transform: translate(-50%, -12px);
}

.footer .square-button:hover .hover-listName {
  opacity: 1;
  transform: translateY(-22px);
}

.notification {
  background-color: #F44336; /* Red color for notifications */
  color: #f8f9fa; /* White text color */
  position: absolute; /* Position absolute for overlay effect */
  top: -1px; /* Position badge slightly above the button */
  right: -3px; /* Position badge slightly to the right of the button */
  width: 18px; /* Width of the badge */
  height: 18px; /* Height of the badge */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Makes the badge circular */
  font-size: 14px; /* Font size for text */
  z-index: 20; /* Ensure badge is on top */
}
/* Toggle Button Styles */
.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #f8f9fa; /* Modern light blue */
  color: #004d40; /* Dark blue for text */
  transition: background-color 0.3s, color 0.3s;
}

.toggle-button.selected {
  background-color: #48ab95; /* Dark blue for selected state */
  color: #f8f9fa;
}

.toggle-button.disabled {
  background-color: #BBDEFB; /* Light blue to indicate disabled state */
  opacity: 0.6;
  cursor: not-allowed;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
}

.delete-button:hover {
  color: #F44336; /* Red color for delete icon on hover */
}

/* Scrollable List */
.scrollable-list {
  max-height: 400px; /* Set a max height for the scrollable list */
  overflow-y: auto;
}

/* Fixed positioning for the toggle button container */
.toggle-btn-container {
  position: fixed;
  top: 50%;
  left: 112%; /* Position off-screen to the right */
  transform: translateY(-50%); /* Center vertically */
  z-index: 1000;
  transition: left 0.3s; /* Smooth transition */
}

/* Positioning when drawer is active */
.toggle-btn-container.active {
  left: 90%; /* Move to the edge of the viewport */
}

/* Style the button */
.toggle-btn {
  background-color: #48ab95;
  color: #f8f9fa;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.toggle-btn:hover {
  background-color: #00695c;
  transform: scale(1.1);
}

.toggle-btn .v-icon {
  font-size: 20px;
}
</style>
