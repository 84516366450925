<template>
  <div class="container">
    <h3 class="title">{{ company }}</h3>

    <div class="info-container">
      <div class="info-item">
        <label for="growth" class="label">Expected Capital:</label>
        <span>{{ growth }} %</span>
      </div>

      <div class="slider-container">
        <div class="slider growth-slider" ref="growthSlider">
          <div
            class="colored-bar growth-colored-bar"
            :style="{ width: growthCursorPosition + 'px' }"
          ></div>
          <div
            class="cursor growth-cursor"
            :style="{ left: growthCursorPosition + 'px' }"
            @mousedown="startDrag('growth', $event)"
            @touchstart="startDrag('growth', $event)"
          ></div>
        </div>
      </div>
    </div>

    <v-card class="dcf-card">
      <div v-if="geoLoading" class="loading-container">
        <v-progress-circular indeterminate color="#004D40"></v-progress-circular>
      </div>

      <div v-else-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>

      <canvas ref="chartCanvas" class="chart-canvas"></canvas>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import Chart from "chart.js/auto";

export default {
  name: "MyValorization",
  props: {
    company: String,
  },
  data() {
    return {
      growthDCF: [],
      duration: 10,
      growth: 6,
      dragging: {
        duration: false,
        growth: false,
      },
      startX: {
        duration: 0,
        growth: 0,
      },
      startValue: {
        duration: 0,
        growth: 0,
      },
      isMounted: false,
      wacc: "",
      geoLoading: false,
      shouldUpdateChart: true,
      errorMessage: "",
    };
  },
  watch: {
    async isMounted(value) {
      if (value) {
        this.$nextTick(() => {
          this.calculateCursorPosition();
          this.fetchData();
        });
      }
    },
    growthDCF: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.createBarChart();
        }
      },
    },
    growth: {
      handler() {
        this.fetchData();
      },
    },
  },
  mounted() {
    this.createBarChart();
    this.isMounted = true;
  },
  computed: {
    durationCursorPosition() {
      return this.calculateCursorPosition(this.duration, 10, "duration");
    },
    growthCursorPosition() {
      return this.calculateCursorPosition(this.growth, 50, "growth");
    },
  },
  methods: {
    fetchData() {
      this.geoLoading = true;
      axios
        .get(
          `https://api.foliosentry.com/api/reverse_dcf?companies=${this.company}&expected_growth_rate=${
            this.growth / 100
          }`
        )
        .then((response) => {
          const item = response.data[0];
          if (!item) {
            this.geoLoading = false;
            this.errorMessage = "No data received";
            console.error("No data received from the server");
            return;
          }
          this.growthDCF = [
            item.reverse_dcf_growth,
            item.avg_cash_flow_growth,
          ];
          this.wacc = item.wacc;
        })
        .catch((error) => {
          this.geoLoading = false;
          this.errorMessage = "Error fetching data";
          console.error("Error fetching data:", error);
        });
    },
    createBarChart() {
      if (!this.shouldUpdateChart) {
        return;
      }
      const ctx = this.$refs.chartCanvas.getContext("2d");
      if (this.chartCanvas) {
        this.chartCanvas.destroy();
      }
      this.chartCanvas = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["Expected Growth", "Last Average Growth"],
          datasets: [
            {
              label: "Growth",
              data: this.growthDCF,
              backgroundColor: [
                "rgba(75, 192, 192, 0.2)",
                "rgba(255, 99, 132, 0.2)",
              ],
              borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Growth",
              },
            },
            x: {
              title: {
                display: true,
                text: "Growth Type",
              },
            },
          },
        },
      });
      this.geoLoading = false;
    },
    calculateCursorPosition(value, max, type) {
      const slider = this.$refs[type + "Slider"];
      if (!slider) return 6;
      const sliderWidth = slider.offsetWidth;
      const cursorWidth = slider.querySelector(".cursor").offsetWidth;
      const range = sliderWidth - cursorWidth;
      return (value / max) * range;
    },
    startDrag(type, event) {
      this.geoLoading = true;
      this.shouldUpdateChart = false;

      this.dragging[type] = true;
      this.startX[type] = event.pageX || event.touches[0].pageX;
      this.startValue[type] = this[type];
      document.addEventListener("mousemove", this.drag);
      document.addEventListener("touchmove", this.drag);
      document.addEventListener("mouseup", this.endDrag);
      document.addEventListener("touchend", this.endDrag);
    },
    drag(event) {
      Object.keys(this.dragging).forEach((type) => {
        if (this.dragging[type]) {
          const x = event.pageX || event.touches[0].pageX;
          const difference = x - this.startX[type];
          const sliderWidth = this.$refs[type + "Slider"].offsetWidth;
          const max = type === "duration" ? 10 : 50;
          const newValue = Math.max(
            6,
            Math.min(max, this.startValue[type] + (difference / sliderWidth) * max)
          );
          if (event.touches) {
            event.preventDefault();
          }
          this[type] = Math.round(newValue);
        }
      });
    },
    endDrag() {
      this.dragging = {
        duration: false,
        growth: false,
      };
      document.removeEventListener("mousemove", this.drag);
      document.removeEventListener("touchmove", this.drag);
      document.removeEventListener("mouseup", this.endDrag);
      document.removeEventListener("touchend", this.endDrag);
      this.shouldUpdateChart = true;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
}

.title {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.label {
  font-size: 1rem;
  color: #333;
}

.slider-container {
  width: 100%;
  max-width: 400px;
}

.slider {
  position: relative;
  height: 10px;
  background-color: #BBDEFB;
  border-radius: 5px;
  margin-top: 10px;
}

.colored-bar {
  position: absolute;
  top: 0;
  background-color: #48ab95;
  left: 0;
  height: 100%;
  border-radius: 5px;
}

.cursor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #004d40;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.dcf-card {
  position: relative;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chart-canvas {
  width: 100% !important; /* Force width to 100% */
  height: 250px !important; /* Force height to 250px */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.error-message {
  color: red;
  text-align: center;
  padding: 10px;
}
</style>
