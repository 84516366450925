<template>
    <div class="home-page">
      <!-- Hero Section -->
      <section class="hero">
        <div class="hero-content">
          <h1>Welcome to Folio Sentry</h1>
          <p>Boost your investment decisions with clear data, intuitive analysis tools, and advanced features !</p>
          <p>all designed to save you time and help you invest with confidence</p>
          <router-link to="/dashboard" class="cta-button">Get Started</router-link>
        </div>
      </section>
  
      <!-- Benefits Section -->
      <section class="benefits">
        <h2>Unlock Your Investment Potential</h2>
        <div class="benefit-cards">
          <div class="benefit-card">
            <v-icon size="48" color="#f44336">mdi-lightbulb-on-outline</v-icon>
            <h3>Smart Insights</h3>
            <p>Get actionable insights and recommendations based on real-time data analysis.</p>
          </div>
          <div class="benefit-card">
            <v-icon size="48" color="#673ab7">mdi-speedometer</v-icon>
            <h3>Performance Metrics</h3>
            <p>Track and optimize your investments with powerful performance metrics.</p>
          </div>
          <div class="benefit-card">
            <v-icon size="48" color="#00bcd4">mdi-chart-bar</v-icon>
            <h3>Visual Comparisons</h3>
            <p>Visualize multiple companies' performance side-by-side in just one glance.</p>
          </div>
        </div>
      </section>
  
      <!-- Features Section (Commented Out Temporarily) -->
      <!-- <section class="features">
        <h2>Why Choose FolioSentry?</h2>
        <div class="feature-list">
          <div class="feature-item">
            <v-icon size="40" color="#4caf50">mdi-chart-line</v-icon>
            <h3>Visualize Financial Data</h3>
            <p>Use dynamic charts and graphs to quickly interpret key financial metrics.</p>
          </div>
          <div class="feature-item">
            <v-icon size="40" color="#fbc02d">mdi-magnify</v-icon>
            <h3>In-Depth Analysis</h3>
            <p>Compare companies side-by-side and evaluate potential investments with precision.</p>
          </div>
          <div class="feature-item">
            <v-icon size="40" color="#2196f3">mdi-newspaper</v-icon>
            <h3>Real-Time Market Insights</h3>
            <p>Stay informed with the latest news, stock prices, and market trends.</p>
          </div>
        </div>
      </section> -->
  
      <!-- Interactive Stats Section (Commented Out Temporarily) -->
      <!-- <section class="interactive-stats">
        <h2>Trusted by Investors Worldwide</h2>
        <div class="stats-grid">
          <div class="stat-item">
            <h3>50,000+</h3>
            <p>Active Users</p>
          </div>
          <div class="stat-item">
            <h3>$10M+</h3>
            <p>Investments Managed</p>
          </div>
          <div class="stat-item">
            <h3>98%</h3>
            <p>User Satisfaction</p>
          </div>
        </div>
      </section> -->
  
      <!-- Testimonials Section with Carousel -->
      <section class="testimonials">
        <h2>What Our Users Say</h2>
        <v-carousel hide-delimiters height="300">
          <v-carousel-item v-for="(chunk, index) in testimonialChunks" :key="index">
            <div class="testimonial-group">
              <div v-for="(testimonial, tIndex) in chunk" :key="tIndex" class="testimonial-card">
                <p>"{{ testimonial.message }}"</p>
                <h4>{{ testimonial.author }}</h4>
                <div class="stars">
                <v-icon v-for="star in 5" :key="star" :color="star <= testimonial.rating ? 'yellow' : 'grey'">
                    mdi-star
                </v-icon>
                </div>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </section>
  
      <!-- Call to Action Section -->
<!-- Call to Action Section -->
<section class="cta-section">
  <h2>Love What We’re Building?</h2>
  <p>Support the beta launch of FolioSentry and help us bring more innovative features to life!</p>
  <a href="https://www.leetchi.com/c/folio-sentry-beta-1616200" target="_blank" class="cta-button cta-donate">
    Contribute Now
  </a>
</section>

    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage',
    data() {
      return {
        testimonials: [
  { 
    message: 'As a beginner investor, this app has been invaluable in helping me avoid bad companies.', 
    author: 'Alex T.', 
    rating: 5 
  },
  { 
    message: 'Very intuitive application that consolidates all the data I struggled to find elsewhere.', 
    author: 'Mélanie D.', 
    rating: 4 
  },
  { 
    message: 'Finally, a site that offers free linear regressions. It was impossible to find before FolioSentry.', 
    author: 'Marc B.', 
    rating: 5 
  },
  { 
    message: 'Spent 2 hours reviewing my performance metrics an amazing tool for any investor!', 
    author: 'Claire G.', 
    rating: 5 
  },
  { 
    message: 'The reverse DCF calculation graphs are amazing—finally, I understand what Xavier Delmas is talking about!', 
    author: 'François L.', 
    rating: 4 
  },
  { 
    message: 'Some improvements could be made, but it’s fantastic to compare companies on a single page.', 
    author: 'Isabelle M.', 
    rating: 3 
  },
  { 
    message: 'Between warning signs and quality indicators, I save so much time on my investment decisions!', 
    author: 'Sophie V.', 
    rating: 5 
  },
  { 
    message: 'What a relief! I can easily find company information without hunting down multiple stock tickers.', 
    author: 'Lucien F.', 
    rating: 4 
  },
]

      };
    },
    computed: {
      testimonialChunks() {
        // Split testimonials into chunks of 3
        const chunkSize = 3;
        let chunks = [];
        for (let i = 0; i < this.testimonials.length; i += chunkSize) {
          chunks.push(this.testimonials.slice(i, i + chunkSize));
        }
        return chunks;
      }
    }
  };
  </script>
  
  <style scoped>
  .home-page {
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: #f9f9f9;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  /* Hero Section */
  .hero {
    width: 100%;
    padding: 60px 20px;
    background: linear-gradient(135deg, #004d40 0%, lightcoral 100%);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .hero-content {
    max-width: 800px;
  }
  
  .hero h1 {
    font-size: 3em;
    margin: 0 0 20px;
    font-weight: bold;
  }
  
  .hero p {
    font-size: 1.5em;
    margin-bottom: 30px;
  }
  
  .cta-section {
  background-color: #fff;
  padding: 60px 20px;
  width: 100%;
  text-align: center;
  border-top: 1px solid #e0e0e0;
}

.cta-section h2 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 10px;
}

.cta-section p {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 30px;
  max-width: 600px;
  margin: 0 auto 30px;
}

.cta-button.cta-donate {
  display: inline-block;
  padding: 15px 30px;
  background-color: #ff5252;
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
  border-radius: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.cta-button.cta-donate:hover {
  background-color: #e53935;
  transform: translateY(-3px);
}
.cta-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #48ab95;
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
  border-radius: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.cta-button:hover {
  background-color: lightcoral;
  transform: translateY(-3px);
}
  
  /* Benefits Section */
  .benefits {
    background-color: #fff;
    padding: 60px 20px;
    width: 100%;
    text-align: center;
  }
  
  .benefits h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #333;
  }
  
  .benefit-cards {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .benefit-card {
    flex: 1 1 30%;
    max-width: 300px;
    background-color: #fafafa;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
  }
  
  .benefit-card:hover {
    transform: translateY(-5px);
  }
  
  .benefit-card h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .benefit-card p {
    font-size: 1em;
    color: #666;
  }
  
  /* Testimonials Section */
  .testimonials {
    background-color: #fff;
    padding: 60px 20px;
    width: 100%;
    text-align: center;
  }
  
  .testimonials h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #333;
  }
  
  .testimonial-group {
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  
  .testimonial-card {
    max-width: 300px;
    padding: 20px;
    background-color: #fafafa;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
  }
  
  .testimonial-card:hover {
    transform: translateY(-5px);
  }
  
  .testimonial-card p {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .testimonial-card h4 {
    font-size: 1.1em;
    color: #555;
  }
  
  .stars {
    margin-top: 10px;
  }
  
  .stars v-icon {
    font-size: 1.2em;
  }
  </style>
  