<template>
    <h3>{{ company }}</h3>
    <div class="content">
      Invest app give money
    </div>
  </template>
  
  <script>
  export default {
    name: 'InvestPromo',
    props: {
      company: String
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style>
  .content {
    text-align: center;
    margin-left: 15%;
    width:70%;
      height:80%;
  }
  </style>
  